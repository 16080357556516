import { FC, ReactNode, useCallback, useMemo, useRef, useState } from 'react';
import { ReactComponent as DismissIcon } from 'components/icons/dismiss.svg';
import { ReactComponent as CollapseIcon } from 'components/Panel/icons/collapse.svg';
import { ReactComponent as ExpandIcon } from 'components/Panel/icons/expand.svg';
import classes from './panel.module.scss';
import { Button, ButtonPropsType } from 'components/Button';
import { createPortal } from 'react-dom';
import cx from 'classnames';
import Tooltip from 'components/Tooltip';
import { Trans } from 'react-i18next';

export type Control = {
  onClick?: () => void;
  title: string;
} & ButtonPropsType;

//TODO: Remove visible param
type TPanel = {
  children?: ReactNode;
  visible: boolean;
  onClose: () => void;
  title: string | JSX.Element;
  controls?: Control[];
  helper?: JSX.Element | string;
  portal?: boolean;
  className?: string;
  id?: string;
};

export const Panel: FC<TPanel> = ({
  onClose,
  className,
  children,
  title,
  visible,
  controls = [],
  helper,
  portal = true,
  id,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const collapse = useCallback(() => {
    setIsCollapsed(true);
  }, []);
  const expand = useCallback(() => {
    setIsCollapsed(false);
  }, []);
  const ref = useRef<HTMLDivElement>(null);
  const content = useMemo(
    () => (
      <>
        {isCollapsed ? (
          <div className={classes.root}>
            <div id={id} className={cx(classes.popup, { [classes.collapsed]: isCollapsed })} ref={ref}>
              <h3 className={classes.headerCollapsed}>
                <Tooltip id="panelCollapse" content={<Trans>Expand</Trans>} display="block">
                  <button type="button" onClick={expand}>
                    <ExpandIcon />
                  </button>
                </Tooltip>
              </h3>
            </div>
          </div>
        ) : (
          <div className={classes.root}>
            <div id={id} className={cx(classes.popup, className)} ref={ref}>
              <button type="button" className={classes.close} onClick={onClose} id="button_panel_close">
                <DismissIcon className={classes.icon} />
              </button>
              <h3 className={classes.header}>
                <Tooltip id="panelCollapse" content={<Trans>Collapse</Trans>} display="block">
                  <button type="button" onClick={collapse}>
                    <CollapseIcon />
                  </button>
                </Tooltip>
              </h3>
              <span className={classes.title}>{title}</span>
              <div className={classes.content} id="panel">
                {children}
              </div>
              {controls.length > 0 && (
                <div className={classes.controls}>
                  <div className={classes.buttons}>
                    {controls.map(({ title, ...props }) => (
                      <Button key={title} {...props}>
                        {title}
                      </Button>
                    ))}
                  </div>
                  {helper && <div className={classes.helper}>{helper}</div>}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    ),
    [isCollapsed, id, expand, className, onClose, collapse, title, children, controls, helper]
  );

  if (!visible) return null;
  return portal ? createPortal(content, document.body) : content;
};
export default Panel;
