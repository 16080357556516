import SinglePage from 'components/SinglePage';
import * as config from 'schemas/facilitator';
import { useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { CLEARANCE_APPROVED, Keys } from 'schemas/facilitator';
import { parseDate } from 'lib/adapter';

export const Facilitator = () => {
  const { t } = useTranslation();

  const getLockMessage = useCallback(
    (data: Record<Keys, any>) => {
      return String(data.bahai_clearance) === CLEARANCE_APPROVED &&
        data.bahai_renewaldate &&
        parseDate(data.bahai_renewaldate, false) < new Date()
        ? {
            message: t('Renewal Date for the Clearance has passed. Please renew again'),
            forceDisplay: true,
            icon: 'warning',
            isWarning: true,
          }
        : {};
    },
    [t]
  );

  return (
    <SinglePage
      getLockMessage={getLockMessage}
      entityName="facilitator"
      displayEdit={true}
      displayRemove={true}
      {...config}
    />
  );
};
