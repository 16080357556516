import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { FieldValidator } from 'final-form';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { AreaTooltipComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { Trans } from 'react-i18next';
import { TLinkEntity } from 'components/ListPage';

export type Keys = (typeof entities.session.columns)[number] | 'bahai_sessionid';

export const defaultMobileColumns: Keys[] = ['bahai_id', 'bahai_name', 'bahai_unitid'];

export const columns = entities.session.columns;

export const isActive = () => true;

export const links = {
  seminar: ['bahai_statuscode'],
} as TLinkEntity;

export const config: AdditionalConfigType<Keys> = {
  bahai_id: {
    searchable: true,
    component: ({ data }) => (
      <HistoryLink to={r.session({ id: data.bahai_sessionid || 0 })}>{data.bahai_id}</HistoryLink>
    ),
  },
  bahai_name: { searchable: true },
  bahai_yearoffered: { isRequired: true },
  bahai_note: {
    component: AreaTooltipComponent,
    fieldProps: ({ classes }) => ({
      className: classes.long,
      inputType: 'area',
      maxLength: 2000,
    }),
  },
  bahai_seminarid: { isDisabled: true },
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  bahai_note: rules.maxLength(2000),
  bahai_yearoffered: rules.required,
  bahai_seminarid: rules.required,
};

export const getTitleFields = (): Keys[] => ['bahai_yearoffered'];

export const getCommonFields = (): Keys[] => ['ownerid', 'bahai_isgpregionid', 'bahai_unitid', 'createdon'];

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [<Trans>General Information</Trans>, ['bahai_seminarid', 'bahai_yearoffered', 'bahai_note']],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [<Trans>General Information</Trans>, ['bahai_seminarid', 'bahai_yearoffered', 'bahai_note']],
];
