import entities from 'config';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { Trans } from 'react-i18next';
import { HistoryLink } from 'components/HistoryLink';
import { routes as r } from 'domain/routes';
import { EmailComponent, PhoneComponent } from 'lib/components';
import * as rules from 'lib/rules';
import { FieldValidator } from 'final-form';

export type Keys = (typeof entities.resource.columns)[number] | 'cluster.bahai_name';

export const defaultMobileColumns: Keys[] = ['fullname', 'mobilephone', 'businessunitid'];

export const columns = [...entities.resource.columns, 'cluster.bahai_name'];

export const isActive = (data: Record<Keys, any>) => !data.isdisabled;

export const isNotEditable = (data: Record<Keys, any>) => {
  if (data.isdisabled) {
    return <Trans>Resource State is Inactive</Trans>;
  }
  return false;
};
export const isNotRemovable = () => (
  <Trans>
    You do not have access to remove a Resource record. Please contact your regional seeker response coordinator or
    Inquiry Services liaison for assistance.
  </Trans>
);

export const config: AdditionalConfigType<Keys | 'systemuserid'> = {
  entityimage: { hiddenForTable: true, excludeFromListQuery: true },
  isdisabled: { hiddenForTable: true },
  createdby: { hiddenForTable: true },
  internalemailaddress: { searchable: true, component: EmailComponent },
  fullname: {
    searchable: true,
    component: ({ data }) => <HistoryLink to={r.resource({ id: data.systemuserid || 0 })}>{data.fullname}</HistoryLink>,
  },
  mobilephone: {
    searchable: true,
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
  homephone: {
    component: PhoneComponent,
    fieldProps: () => ({
      inputType: 'phone',
    }),
  },
};

export const validation: Partial<Record<Keys, FieldValidator<any>>> = {
  middlename: rules.maxLength(25),
  mobilephone: rules.phone,
  homephone: rules.phone,
};

export const getDetailsConfig: FormConfigGetter<Keys> = () => [
  [<Trans>Personal Data</Trans>, ['lastname', 'firstname', 'fullname', 'middlename', 'businessunitid'] as Keys[]],
  [<Trans>Contact Data</Trans>, ['internalemailaddress', 'mobilephone', 'homephone'] as Keys[]],
];

export const getFormConfig: FormConfigGetter<Keys> = () => [
  [<Trans>Personal Data</Trans>, ['firstname', 'lastname', 'middlename'] as Keys[]],
  [<Trans>Contact Data</Trans>, ['mobilephone', 'homephone'] as Keys[]],
];
