import { FieldValidator } from 'final-form';
import { AdditionalConfigType, TEntityName } from 'lib/types';
import { TConfig } from 'components/Table';
import { getDefaultAdapter } from 'lib/adapter';
import { useContext, useMemo } from 'react';
import { MetaDataContext } from 'providers/MetaDataProvider';
import { EntityLink, TLinkEntity } from 'components/ListPage';
import { useComponents } from 'lib/components';
import { AuthContext } from 'providers/AuthProvider';
import { TAppFieldMeta } from 'dynamics-meta';

const environment = process.env.REACT_APP_ENV || 'dev';

export const debounce = (fn: () => any, ms: number) => {
  let timer: ReturnType<typeof setTimeout>;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(fn, ms);
  };
};

export const devLog = (...message: any[]) => {
  if (['qa', 'dev'].includes(environment)) {
    console.log(...message);
  }
};

export const createValidation = (values: Record<string, any>, validators: Record<string, FieldValidator<any>>) => {
  return Object.fromEntries(Object.entries(validators).map(([field, rule]) => [field, rule(values[field], values)]));
};

export const useTableConfig = (
  baseConfig: Record<string, TAppFieldMeta>,
  additionalConfig: AdditionalConfigType<string>,
  entityName: TEntityName,
  links?: TLinkEntity
): Record<string, TConfig<Record<string, any>>> => {
  const { config } = useContext(MetaDataContext);
  const { getDefaultComponent } = useComponents(entityName);
  return useMemo(
    () =>
      Object.fromEntries([
        ...Object.entries(baseConfig).map(([name, { label, type, format, description }]) => {
          return [
            name,
            {
              name,
              label,
              description,
              type,
              adapter: getDefaultAdapter(type, format),
              component: getDefaultComponent(name),
              ...additionalConfig[name],
            },
          ];
        }),
        ...(links
          ? Object.entries(links)
              .map(([entity, linkProps]) =>
                (Array.isArray(linkProps) ? (linkProps as string[]) : (linkProps as EntityLink).fields).map((name) => {
                  try {
                    const { type, label, format } = config[entity as TEntityName].fields[name];
                    return [
                      `${entity}.${name}`,
                      {
                        name: `${entity}.${name}`,
                        label: `${label} (${config?.[entity as TEntityName].displayName})`,
                        type,
                        adapter: getDefaultAdapter(type, format),
                        component: getDefaultComponent(`${entity}.${name}`),
                        ...additionalConfig[`${entity}.${name}`],
                      },
                    ];
                  } catch (e) {
                    console.error(`Cant find config for ${entity}.${name}`);
                    return [];
                  }
                })
              )
              .flat()
          : []),
      ]),
    [additionalConfig, baseConfig, config, getDefaultComponent, links]
  );
};

export const ucFirst = (label: string) => label.replace(/\s/, (letter) => letter.toUpperCase());

export const useSystemUserId = () => {
  const { systemuserid } = useContext(AuthContext);
  return systemuserid;
};
