import SinglePage from 'components/SinglePage';
import * as config from 'schemas/session';
import { Trans, useTranslation } from 'react-i18next';

import * as participantConfig from 'schemas/participant';
import { ApiFilter } from 'lib';
import ListPage from 'components/ListPage';
import { useCallback, useMemo } from 'react';
import { Action } from 'components/Actions';
import { useAttendance, useInviteParticipant } from 'schemas/session/components';
import { SEMINAR_FINISHED } from 'schemas/seminar';

export const ParticipantTab = ({
  bahai_sessionid,
  seminarFinished,
}: {
  bahai_sessionid: string;
  seminarFinished: boolean;
}) => {
  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_sessionid', value: bahai_sessionid }] }],
    [bahai_sessionid]
  );

  const { action: inviteAction, content: inviteContent } = useInviteParticipant(bahai_sessionid);
  const { actions: attendanceActions } = useAttendance();

  const getActions = useCallback(
    (baseActions: Action[]) => [
      inviteAction,
      ...baseActions.filter((a) => ['refresh', 'delete'].includes(a.name)),
      ...(seminarFinished ? attendanceActions : ([] as Action[])),
    ],
    [attendanceActions, inviteAction, seminarFinished]
  );

  return (
    <>
      {inviteContent}
      <ListPage
        systemView="Default for Session"
        entityName="participant"
        isCreateHidden
        hiddenFilters={hiddenFilters}
        displayViews={false}
        allowRowClick={false}
        {...participantConfig}
        {...{ getActions }}
      />
    </>
  );
};

export const Session = () => {
  const { t } = useTranslation();

  return (
    <SinglePage
      mainSectionName={<Trans>Basic Information</Trans>}
      entityName="session"
      displayEdit={true}
      displayRemove={true}
      {...config}
      additionalTabs={[
        {
          label: t('Participants'),
          tab: 'participant',
          isDefault: true,
          content: ({ bahai_sessionid, 'seminar.bahai_statuscode': seminarStatus }) => (
            <ParticipantTab {...{ bahai_sessionid, seminarFinished: String(seminarStatus) === SEMINAR_FINISHED }} />
          ),
        },
      ]}
    />
  );
};
