import { useCallback, useMemo } from 'react';
import classes from './radioswitch.module.scss';
import cx from 'classnames';
import { TInputProps } from 'components/Form/types';
import { RadioSwitch as RadioSwitchBase } from 'components/RadioSwitch';
import { ValidationMessage } from 'components/Form/ValidationMessage';

type TSelectProps = TInputProps<string | string[] | undefined> & {
  items: Map<string, string | JSX.Element>;
};

const RadioSwitch = ({
  label,
  meta,
  input: { name, value, onChange },
  className,
  readOnly = false,
  ...props
}: TSelectProps) => {
  const errors = useMemo(() => (meta.touched ? meta.error || meta.submitError : undefined), [meta]);

  const onInputChange = useCallback(() => onChange(!value), [onChange, value]);

  return (
    <div
      className={cx(classes.wrapper, className, {
        [classes.disabled]: readOnly,
      })}
    >
      <div className={classes.flex}>
        <label className={cx(classes.label, { [classes.required]: props.required })} htmlFor={name}>
          {label}
        </label>
        <RadioSwitchBase selectedValue={!!value} onChange={onInputChange} name={name} {...props} />
      </div>
      <div className={classes.bottomWrapper}>{!readOnly && <ValidationMessage error={errors} absolute={true} />}</div>
    </div>
  );
};

export const createRadioSwitch = (items: Map<string, string | JSX.Element>) => {
  return (props: TInputProps<string | string[] | undefined>) => <RadioSwitch {...props} items={items} />;
};
