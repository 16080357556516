import { Component, ContextType } from 'react';
import { ErrorPage } from './ErrorPage';
import { ReactComponent as Error500Icon } from './icons/Error500.svg';
import { ReactComponent as Error400Icon } from './icons/Error400.svg';
import { ReactComponent as Error401Icon } from './icons/Error401.svg';
import { ReactComponent as Error404Icon } from './icons/Error404.svg';
import { ReactComponent as Error503Icon } from './icons/Error503.svg';
import { ReactComponent as ErrorSWWIcon } from './icons/SomethingWentWrong.svg';
import { Trans } from 'react-i18next';
import { ErrorContext, ServerErrorType } from 'providers/ErrorProvider';

class ErrorBoundary extends Component<{
  children: (showMenu: boolean, error: JSX.Element | undefined, showHeader: boolean) => JSX.Element;
}> {
  static contextType = ErrorContext;

  constructor(props: any) {
    super(props);
  }

  static getDerivedStateFromError() {
    return {};
  }

  componentDidCatch() {
    if (!(this.context as ContextType<typeof ErrorContext>).error) {
      (this.context as ContextType<typeof ErrorContext>).addError(ServerErrorType.ErrorDefault);
    }
  }

  render() {
    const errorsWithMenu = [ServerErrorType.Errr400, ServerErrorType.Errr500];

    const contextError = (this.context as ContextType<typeof ErrorContext>).error;

    let error = undefined as JSX.Element | undefined;
    const showMenu = !!contextError && errorsWithMenu.includes(contextError);
    const showHeader = contextError !== ServerErrorType.Errr401;

    switch (contextError) {
      case ServerErrorType.Errr400:
        error = (
          <ErrorPage
            title={<Trans>Bad Request</Trans>}
            description={<Trans>The server can`t or won`t process the request</Trans>}
            CustomIcon={Error400Icon}
            showRefresh={true}
          />
        );
        break;
      case ServerErrorType.Errr401:
        error = (
          <ErrorPage
            title={<Trans>Unauthorized</Trans>}
            description={<Trans>The page is not publicly available</Trans>}
            CustomIcon={Error401Icon}
            showRefresh={true}
          />
        );
        break;
      case ServerErrorType.Errr403:
      case ServerErrorType.Errr404:
        error = (
          <ErrorPage
            title={<Trans>You don't have access to this record</Trans>}
            description={
              <Trans>The requested record was not found or you do not have sufficient permissions to view it</Trans>
            }
            CustomIcon={Error404Icon}
          />
        );
        break;
      case ServerErrorType.Errr500:
        error = (
          <ErrorPage
            title={<Trans>Internal Server Error</Trans>}
            description={<Trans>We are working towards creating something better</Trans>}
            showRefresh={true}
            CustomIcon={Error500Icon}
          />
        );
        break;
      case ServerErrorType.Errr503:
      case ServerErrorType.Errr504:
        error = (
          <ErrorPage
            title={<Trans>The server is temporarily unavailable. Please try again later</Trans>}
            showRefresh={true}
            showGoHome={false}
            CustomIcon={Error503Icon}
          />
        );
        break;
      case ServerErrorType.ErrorDefault:
        error = (
          <ErrorPage
            title={<Trans>Something went wrong</Trans>}
            description={<Trans>Sorry, something went wrong there. Please try again</Trans>}
            CustomIcon={ErrorSWWIcon}
            showRefresh={true}
          />
        );
        break;
    }

    return this.props.children(error ? showMenu : true, error, showHeader);
  }
}

export default ErrorBoundary;
