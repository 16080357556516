import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { MetaDataContext } from 'providers/MetaDataProvider';
import { FetchQuery, SavedQuery, TEntityName } from 'lib/types';
import { useApi } from 'domain/api';
import { xml2js } from 'xml-js';
import { Direction } from 'components/Table/hooks';
import { useForm, useFormState } from 'react-final-form';
import config from 'config/index';
import { routes } from 'domain/routes';
import { AuthContext } from 'providers/AuthProvider';
import { useTranslation } from 'react-i18next';
import { useNotifications } from 'providers/NotificationsProvider';
import { useRecord } from 'lib/record';
import { getFormattedNow, TimeZone } from 'lib/adapter';
import { saveAs } from 'file-saver';
import { devLog } from 'lib/helpers';
import { RecordExport } from 'components/Export';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as ExportIcon } from 'components/Actions/icons/export.svg';
import { useLoader } from 'providers/LoaderProvider';
import { millisecondsToHours } from 'date-fns';
import { getTimezoneOffset } from 'date-fns-tz';

const env = process.env.REACT_APP_ENV || 'dev';

type Order = {
  attributes: {
    attribute: string;
    entityname?: string;
    descending?: 'true' | 'false';
  };
};

export type ParsedQuery = ReturnType<typeof parseView>;

const parseView = ({ savedqueryid, userqueryid, name, isdefault, fetchxml, layoutxml, description }: SavedQuery) => {
  const xml = xml2js(fetchxml);
  const rows = xml2js(layoutxml);

  const sorting: { name: string; direction: Direction }[] = xml.elements[0].elements[0].elements
    .filter((v: any) => v.name === 'order')
    .map(({ attributes: { attribute, entityname, descending } }: Order) => ({
      name: [entityname, attribute].filter((v) => v).join('.'),
      direction: descending === 'true' ? 'desc' : 'asc',
    }));

  const getAliasName = (joinAttributes: Record<string, any>) => {
    const entities = Object.keys(config)
      .filter((key) => config[key as TEntityName].name === joinAttributes.name)
      .filter((key, _, arr) => (arr.length > 1 ? joinAttributes.to.includes(key) : true));
    if (entities.length !== 1) throw new Error(`Can't find alias for join: ${JSON.stringify(joinAttributes)}`);
    return entities[0];
  };

  const aliases = Object.fromEntries(
    xml.elements[0].elements[0].elements
      .filter((v: any) => v.name === 'link-entity')
      .map((v: any) => [v.attributes.alias, getAliasName(v.attributes)])
  );

  const filters: Element[] = xml.elements[0].elements[0].elements.filter((v: any) => v.name === 'filter');

  const links: Element[] = xml.elements[0].elements[0].elements.filter((v: any) => v.name === 'link-entity');

  const settings = (
    rows.elements[0].elements[0].elements.map((v: any) => v.attributes) as Array<{
      name: string;
      width: number | string;
    }>
  ).map(({ name, width }) => ({
    name: name.includes('.') ? aliases[name.split('.')[0]] + '.' + name.split('.')[1] : name,
    width: Number(width) || 120,
  }));

  return {
    id: (savedqueryid || userqueryid) as string,
    name,
    isSystem: !!savedqueryid,
    description,
    isDefault: !!isdefault,
    settings,
    sorting,
    filters,
    links,
  };
};

export const useGlobalMetaData = () => {
  const { config } = useContext(MetaDataContext);

  const getConfigByLogicalName = useCallback(
    (logicalName: string) => Object.values(config).find((v) => v.logicalName === logicalName),
    [config]
  );

  return { getConfigByLogicalName };
};

//type HiddenField = `${TEntityName}.${string}`;
//type HideRule = (roles: string[]) => HiddenField[];

const getHiddenFields = (roles: string[]): string[] => (roles ? [] : []);

export const useMetaData = (entityName: TEntityName = 'resource', systemView?: string) => {
  const { config, joinParams } = useContext(MetaDataContext);
  const { securityRoles } = useContext(AuthContext);
  const hiddenFields = useMemo(
    () =>
      getHiddenFields(securityRoles).map((v) => (v.startsWith(entityName + '.') ? (v.split('.').pop() as string) : v)),
    [entityName, securityRoles]
  );

  const { getSystemViews, getUserViews } = useApi();

  const {
    logicalName,
    displayName,
    displayCollectionName,
    PrimaryIdAttribute,
    PrimaryNameAttribute,
    PrimaryImageAttribute,
    OwnershipType,
    ObjectTypeCode,
  } = useMemo(() => config[entityName], [config, entityName]);

  const getJoinLogicalName = useCallback(
    (fieldName: string) => {
      if (fieldName.includes('.')) {
        const [entityName, field] = fieldName.split('.');
        return config[entityName].fields[field].targets[0];
      }
      return config[entityName].fields[fieldName].targets[0];
    },
    [config, entityName]
  );

  const getLookupRoute = useCallback(
    (fieldName: string) => {
      const logicalName = getJoinLogicalName(fieldName);
      const entityName = Object.keys(config).find((v) => config[v as keyof typeof config].logicalName === logicalName);
      return entityName ? routes[entityName as keyof typeof routes] : undefined;
    },
    [config, getJoinLogicalName]
  );

  const getTargetLogicalNames = useCallback(
    (fieldName: string) => {
      try {
        return config[entityName as TEntityName].fields[fieldName].targets;
      } catch (e) {
        console.error(`Cant get targets for "${fieldName}"`);
        return [];
      }
    },
    [config, entityName]
  );

  const getJoinUrl = useCallback(
    (fieldName: string) => joinParams[getJoinLogicalName(fieldName)].LogicalCollectionName,
    [getJoinLogicalName, joinParams]
  );

  const entityConfig = useMemo(() => config[entityName], [config, entityName]);

  const getViews = useCallback(async () => {
    const {
      data: { value: defaultQueries },
    } = await getSystemViews(logicalName, systemView);
    const {
      data: { value: userQueries },
    } = await getUserViews(logicalName);
    return {
      defaultQueries: defaultQueries.filter((v) => v.layoutxml !== null).map(parseView),
      userQueries,
    };
  }, [getSystemViews, getUserViews, logicalName, systemView]);

  const getFieldDefinition = useCallback(
    (name: string) => {
      if (name.includes('.')) {
        const [entity, field] = name.split('.');
        return config[entity as TEntityName].fields[field];
      }
      return entityConfig.fields[name];
    },
    [config, entityConfig.fields]
  );

  const getLabel = useCallback(
    (name: string) => {
      if (name.includes('.')) {
        const [entity] = name.split('.');
        return getFieldDefinition(name).label + ` (${config[entity as TEntityName].displayName})`;
      } else {
        return getFieldDefinition(name).label;
      }
    },
    [config, getFieldDefinition]
  );

  const getFieldType = useCallback((name: string) => getFieldDefinition(name).type, [getFieldDefinition]);

  return {
    config,
    displayName,
    displayCollectionName,
    getLabel,
    getFieldType,
    entityName,
    getJoinLogicalName,
    getTargetLogicalNames,
    getLookupRoute,
    entityConfig,
    getJoinUrl,
    logicalName,
    joinParams,
    getViews,
    ObjectTypeCode,
    PrimaryIdAttribute,
    PrimaryNameAttribute,
    PrimaryImageAttribute,
    getFieldDefinition,
    url: entityConfig.url,
    OwnershipType,
    hiddenFields,
  };
};

export const removePageFromQuery = (query: FetchQuery) => {
  const {
    fetch: {
      _attributes: { page: _, count: _c, ..._attributes },
      ...rest
    },
  } = query;
  return {
    fetch: {
      _attributes,
      ...rest,
    },
  } as FetchQuery;
};

export const useQuery = () => {
  const { joinParams } = useContext(MetaDataContext);
  const { findOneBy } = useApi();

  const findIdByName = useCallback(
    async (entity: string, name: string) => {
      try {
        const { LogicalCollectionName, PrimaryIdAttribute, PrimaryNameAttribute } = joinParams[entity];
        const {
          data: { value },
        } = await findOneBy(LogicalCollectionName, PrimaryIdAttribute, PrimaryNameAttribute, name);
        if (value.length) return value[0][PrimaryIdAttribute];
      } catch (e) {
        console.error(entity, name);
      }
    },
    [joinParams, findOneBy]
  );

  return { findIdByName };
};

export const useFormChanges = <T extends string>(fields: T[]) => {
  const { values } = useFormState();
  const { batch, change } = useForm();
  const previousValues = useRef<Partial<Record<T, any>>>(
    Object.fromEntries(fields.map((key) => [key, values[key]])) as Partial<Record<T, any>>
  );

  const changes = useMemo(
    () =>
      Object.fromEntries(
        fields.filter((field) => previousValues.current[field] !== values[field]).map((field) => [field, values[field]])
      ),
    [fields, values]
  );

  useEffect(() => {
    previousValues.current = Object.fromEntries(
      fields.map((field) => [field, values[field]]).filter((v) => v[1] !== undefined)
    ) as Partial<Record<T, any>>;
  }, [fields, values]);

  const update = useCallback(
    (newValues: Record<string, any>) => {
      if (Object.keys(newValues).length === 0) return;
      batch(() => {
        Object.entries(newValues).forEach(([name, value]) => change(name, value));
      });
    },
    [batch, change]
  );

  return { changes, update };
};

export const useRemove = (entityName: TEntityName, customRemove?: (data: Record<string, any>) => void) => {
  const {
    entityConfig: { url },
    PrimaryIdAttribute,
  } = useMetaData(entityName);

  const [loading, setLoading] = useState(false);
  const { request } = useApi();

  const remove = useCallback(
    async (data: Record<string, any>) => {
      setLoading(true);
      try {
        if (customRemove) {
          await customRemove(data);
        } else {
          const id = data[PrimaryIdAttribute];
          await request({
            url: `${url}(${id})`,
            method: 'delete',
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [request, url, PrimaryIdAttribute, customRemove]
  );

  const removeWithConfirmation = useCallback(
    async (bahai_confirmationmessage: string, data: Record<string, any>) => {
      setLoading(true);
      const id = data[PrimaryIdAttribute];
      await request({
        url: `${url}(${id})`,
        method: 'patch',
        eTag: '*',
        data: {
          bahai_confirmationmessage,
        },
      });
      await remove(data);
    },
    [remove, request, url, PrimaryIdAttribute]
  );

  return { remove, removeWithConfirmation, loading };
};

const safeParse = (key: string) => {
  const value = window.localStorage.getItem(key);
  if (!value) return;
  try {
    return JSON.parse(value);
  } catch (e) {
    console.error('Incorrect value on LocalStorage in ' + key);
  }
};

export const useSyncStorage = <T,>(key: string, keepValue = true): [T | undefined, (value?: T) => void] => {
  const [value, setValue] = useState<T | undefined>(() => safeParse(key));

  const readValue = useCallback(
    (e: Record<string, any>) => {
      if (e.key && e.key === key) {
        if (!keepValue || safeParse(key)) setValue(safeParse(key));
      }
    },
    [keepValue, key]
  );

  const setSyncValue = useCallback(
    (value?: T) => {
      if (value) {
        window.localStorage.setItem(key, JSON.stringify(value));
      } else {
        window.localStorage.removeItem(key);
      }
      setValue(value);
    },
    [key]
  );

  useEffect(() => {
    window.addEventListener('storage', readValue);
    return () => {
      window.removeEventListener('storage', readValue);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [value, setSyncValue];
};

export const useExport = (
  id: string,
  entityName: TEntityName,
  tabNames: TEntityName[],
  getFileName: (data: Record<string, any>, format: string) => string,
  logExporter = true
) => {
  const [loading, setLoading] = useState(false);
  const [contentVisible, setContentVisible] = useState(false);
  const { t } = useTranslation();
  const { addActionUncompleted } = useNotifications();
  const { getToken, fullName } = useContext(AuthContext);
  const [data, setData] = useState({} as Record<string, any>);
  const { patch } = useRecord(entityName);
  const { showLoader, hideLoader } = useLoader();
  const { timeZone } = useTimeZone();

  const sendRequest = useCallback(
    async (tabs: string[], format: string, openInBrowser = false) => {
      setLoading(true);
      try {
        const token = await getToken();
        const response = await fetch(
          '/api/Export?' +
            new URLSearchParams({
              id,
              tabs,
              entityName,
              timeZone,
              env,
              format,
            } as unknown as Record<string, any>),
          {
            method: 'get',
            headers: {
              ...(token ? { 'X-token': 'Bearer ' + token } : {}),
            },
          }
        );
        if (!String(response.status).startsWith('2')) {
          throw response;
        }
        if (logExporter) await patch({ bahai_lastexportcreator: `${fullName} ${getFormattedNow()}` }, id);
        if (openInBrowser) {
          const file = new File([await response.blob()], getFileName(data, format), { type: 'application/pdf' });
          const url = URL.createObjectURL(file);
          window.open(url, '_blank');
        } else {
          saveAs(await response.blob(), getFileName(data, format));
        }
        setContentVisible(false);
      } catch (e) {
        addActionUncompleted(t('Something went wrong'));
        devLog(e);
      } finally {
        setLoading(false);
      }
    },
    [getToken, id, entityName, timeZone, logExporter, patch, fullName, getFileName, data, addActionUncompleted, t]
  );

  const content = useMemo(
    () =>
      contentVisible ? (
        <RecordExport
          tabNames={tabNames}
          loading={loading}
          onClose={() => setContentVisible(false)}
          onExport={sendRequest}
        />
      ) : null,
    [contentVisible, loading, sendRequest, tabNames]
  );

  const action: Action = useMemo(
    () => ({
      title: t('Export'),
      name: 'personExport',
      onClick: ({ selectedItems: [data] }) => {
        setData(data);
        if (entityName === 'historylog') {
          showLoader(t('Loading...'));
          sendRequest([], 'pdf').finally(() => hideLoader());
        } else {
          setContentVisible(true);
        }
      },
      display: ({ context }) => context === ActionContext.SinglePage,
      Icon: ExportIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SinglePage,
      allowedDevices: AllowedDevices.All,
    }),
    [entityName, hideLoader, sendRequest, showLoader, t]
  );

  return { action, content };
};

export const timeZoneToString = (timeZone: string, displayRegion = false) => {
  const offset = millisecondsToHours(getTimezoneOffset(timeZone));
  const offsetString = `(GMT ${offset > 0 ? '+' : ''}${offset}:00)`;

  const [region, ...cities] = timeZone.split('/');

  return [offsetString, displayRegion ? region + ' -' : '', cities.join(' ').replace('_', ' ')]
    .filter((v) => !!v)
    .join(' ');
};

export const useTimeZone = () => {
  const timeZone = useMemo(() => TimeZone.getInstance().getTimeZone(), []);

  const updateTimeZone = useCallback((timeZone: string) => {
    localStorage.setItem('timeZone', timeZone);
    location.reload();
  }, []);

  return { timeZone, updateTimeZone };
};
