import SinglePage from 'components/SinglePage';
import * as config from 'schemas/application';
import { Trans, useTranslation } from 'react-i18next';
import { useCallback } from 'react';
import { Keys } from 'schemas/application';

export const Application = () => {
  const { t } = useTranslation();

  const getLockMessage = useCallback(
    (data: Record<Keys, any>) =>
      data.bahai_isdraft
        ? {
            message: t('Draft Application'),
            forceDisplay: true,
            icon: 'info',
          }
        : {},
    [t]
  );

  return (
    <SinglePage
      entityName="application"
      displayEdit={true}
      displayRemove={true}
      {...config}
      mainSectionName={<Trans>Basic Information</Trans>}
      getLockMessage={getLockMessage}
      allowDraft
    />
  );
};
