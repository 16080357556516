import { ButtonHTMLAttributes } from 'react';
import classes from './button.module.scss';
import cx from 'classnames';

export type ButtonPropsType = {
  role?: 'primary' | 'flat' | 'secondary';
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Button = ({ role = 'secondary', type = 'button', className, ...props }: ButtonPropsType) => {
  return <button className={cx(classes.button, classes[role], classes.secondary, className)} type={type} {...props} />;
};
