import SinglePage from 'components/SinglePage';
import * as config from 'schemas/person';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Action } from 'components/Actions';
import { useParams } from 'react-router-dom';
import HistoryLogTab from 'components/HistoryLogTab';
import { useArchiveModal, useRegisteredModal } from 'schemas/person/components';

export const Person = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const { actions: archiveModalactions, content: archiveModalContent } = useArchiveModal(id);
  const { actions: registeredModalactions, content: registeredLoaderContent } = useRegisteredModal(id);

  const getActions = useCallback(
    (baseActions: Action[]) => baseActions.concat(...archiveModalactions, ...registeredModalactions),
    [archiveModalactions, registeredModalactions]
  );

  return (
    <>
      {archiveModalContent}
      {registeredLoaderContent}
      <SinglePage
        entityName="person"
        displayEdit={true}
        displayRemove={true}
        {...config}
        getActions={getActions}
        additionalTabs={[
          {
            label: t('History Log'),
            tab: 'historylog',
            content: (data: Record<string, any>) => <HistoryLogTab id={id || ''} data={data} />,
          },
        ]}
      />
    </>
  );
};
