import { useTranslation } from 'react-i18next';
import * as config from 'schemas/historylog';
import { SimpleTable } from 'components/SimpleTable';
import { useCallback, useContext, useState, useMemo } from 'react';
import { ScreenContext } from 'providers/ScreenProvider';
import classes from './style.module.scss';
import { ReactComponent as RefreshIcon } from 'components/Actions/icons/refresh.svg';
import { IconButton } from 'components/IconButton';
import { ApiFilter, TEntityName } from 'lib';
import { ReactComponent as ZoomOutIcon } from 'components/Dialog/icons/zoom-out.svg';
import { ReactComponent as ZoomIcon } from 'components/Dialog/icons/zoom-in.svg';
import cx from 'classnames';
import meta, { systemBasedFields } from 'config';
import { useNotifications } from 'providers/NotificationsProvider';
import { useExport } from 'lib/hooks';
import { ActionContext } from 'components/Actions';
import { getFormattedNow } from 'lib/adapter';

export const Improver = () => <></>;

const HistoryLogTab = ({ id, data }: { id: string; data: Record<string, any> }) => {
  const { isMobile } = useContext(ScreenContext);
  const { t } = useTranslation();
  const { addActionUncompleted } = useNotifications();

  const dataMapper = useCallback(
    (data: Record<string, any>[]) => {
      if (data.length > 0 && data[0].bahai_istruncated) {
        addActionUncompleted(
          t(
            'History Log is too large to display. Some related records were excluded. If you need to have larger History please Export PDF.'
          )
        );
      }

      return data
        .map(({ bahai_changedata, ...rest }) => ({
          changeList: JSON.parse(bahai_changedata).changedAttributes as Record<string, any>[],
          ...rest,
        }))
        .map(({ changeList, ...rest }) => {
          const entityName = Object.entries(meta).find(
            ([_, v]) => v.name === (rest as any).bahai_entitylogicalname
          )?.[0] as TEntityName;
          return {
            ...rest,
            changeList: changeList.filter(({ logicalName }: Record<string, any>) =>
              (meta[entityName]?.columns.map((v: string) => v) as string[])
                .concat(...systemBasedFields)
                .includes(logicalName)
            ),
          };
        })
        .filter(({ changeList }) => changeList.length > 0)
        .map(({ changeList, ...rest }) => ({
          ...rest,
          bahai_changedata: JSON.stringify({ changedAttributes: changeList }),
        }));
    },
    [addActionUncompleted, t]
  );

  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_personid', value: id }] }],
    [id]
  );

  const [isFullScreen, setIsFullScreen] = useState(false);
  const toggleFullScreen = useCallback(() => setIsFullScreen((v) => !v), []);

  const getFileName = useCallback(
    (_: Record<string, any>, format: string) =>
      ['HistoryLog', data.bahai_firstname, data.bahai_lastname, getFormattedNow()].filter((v) => !!v).join('_') +
      '.' +
      format,
    [data.bahai_firstname, data.bahai_lastname]
  );

  const { action: exportAction, content: exportContent } = useExport(id, 'historylog', [], getFileName, false);

  const onExport = useCallback(
    () =>
      exportAction.onClick({
        context: ActionContext.ListPage,
        selectedItems: [data],
        reload: () => null,
        query: null,
      }),
    [data, exportAction]
  );

  return (
    <div className={cx(classes.wrapper, { [classes.fullScreen]: isFullScreen })}>
      {exportContent}
      {isMobile ? (
        <div className={classes.mobile}>
          {t('A lot of data to display for current device. Please use Tablet or Desktop')}
        </div>
      ) : (
        <SimpleTable
          entityName="historylog"
          fixedRows={false}
          dataMapper={dataMapper}
          distinct={false}
          hiddenFilters={hiddenFilters}
          props={{ sorting: [{ name: 'bahai_changeddatetime', direction: 'desc' }] }}
          {...config}
        >
          {({ reload }) => (
            <div className={classes.header}>
              <IconButton Icon={RefreshIcon} onClick={reload}>
                {t('Refresh')}
              </IconButton>
              <IconButton Icon={exportAction.Icon} onClick={onExport}>
                {exportAction.title}
              </IconButton>
              <button type="button" className={classes.zoom} onClick={toggleFullScreen}>
                {isFullScreen ? <ZoomOutIcon className={classes.icon} /> : <ZoomIcon className={classes.icon} />}
              </button>
            </div>
          )}
        </SimpleTable>
      )}
    </div>
  );
};

export default HistoryLogTab;
