import { Link, useLocation } from 'react-router-dom';
import classes from './style.module.scss';
import { mathRouteName, routes, routes as r, TRouteName } from 'domain/routes';
import { useCallback, useEffect, useState } from 'react';
import { TEntityName } from 'lib';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';
import { useApi } from 'domain/api';
import { matchPath } from 'react-router';

const config = meta.meta as unknown as Record<TEntityName, TAppEntityMeta>;

export const BreadCrumbs = () => {
  const { pathname } = useLocation();
  const { request } = useApi();

  const [list, setList] = useState<Array<{ to: string; label: string }>>([]);

  const generate = useCallback(async () => {
    const array: Array<{ to: string; label: string }> = [];
    const routeName = mathRouteName(pathname);
    if (routeName) {
      const entityName = routeName.replace('List', '') as TEntityName;
      const { url, displayCollectionName, PrimaryNameAttribute } = config[entityName];
      const matchedRoute = matchPath<{ id: string }>(pathname, {
        path: routes[entityName as TRouteName].path,
        exact: true,
      });
      if (r[(entityName + 'List') as TRouteName]) {
        array.push({ to: r[(entityName + 'List') as TRouteName].path, label: displayCollectionName });
      }
      if (!routeName.includes('List') && matchedRoute) {
        if (entityName === 'session') {
          const {
            data: {
              bahai_name: sessionName,
              bahai_seminarid: { bahai_name: seminarName, bahai_seminarid },
            },
          } = await request<Record<string, any>>({
            url: `${url}(${matchedRoute.params.id})`,
            params: {
              $select: `${PrimaryNameAttribute},bahai_seminarid`,
              $expand: 'bahai_seminarid($select=bahai_name)',
            },
          });
          array.push({ to: r.seminarList.path, label: config.seminar.displayCollectionName });
          array.push({
            to: r.seminar({ id: bahai_seminarid }),
            label: seminarName,
          });
          array.push({
            to: r.seminar({ id: bahai_seminarid, tab: 'session' }),
            label: config.session.displayCollectionName,
          });
          array.push({ to: pathname, label: sessionName });
        } else {
          const {
            data: { [PrimaryNameAttribute]: name },
          } = await request<Record<string, any>>({
            url: `${url}(${matchedRoute.params.id})`,
            params: { $select: PrimaryNameAttribute },
          });
          array.push({ to: pathname, label: name });
        }
      }
      setList(array);
    }
  }, [pathname, request]);

  useEffect(() => {
    generate().then();
  }, [generate, pathname]);

  return (
    <div className={classes.root}>
      <Link to="/">Home</Link>
      <>
        {list.map(({ to, label }) => (
          <Link key={to} to={to}>
            {label}
          </Link>
        ))}
      </>
    </div>
  );
};
