import cx from 'classnames';
import { ReactComponent as ErrorIcon } from '../../../components/Panel/icons/error.svg';

import classes from 'components/Form/Input/input.module.scss';

export const ValidationMessage = ({ error = '', absolute = true }: { error?: string; absolute?: boolean }) => {
  if (!error) return null;

  return (
    <div className={cx(classes.error, { [classes.absolute]: absolute })}>
      <div style={{ display: 'flex', justifyContent: `center`, gap: '4px', marginTop: `2px` }}>
        <ErrorIcon />
        {error}
      </div>
    </div>
  );
};
