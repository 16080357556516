import { createContext, useContext, FC, useCallback, useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
import { useSyncStorage } from 'lib/hooks';

export enum ServerErrorType {
  Errr400 = 400,
  Errr401 = 401,
  Errr403 = 403,
  Errr404 = 404,
  Errr500 = 500,
  Errr503 = 503,
  Errr504 = 504,
  ErrorDefault = 1,
}

export const ErrorContext = createContext(
  {} as {
    error: ServerErrorType | undefined;
    addError: (error: ServerErrorType) => void;
    clearError: () => void;
  }
);

export const ErrorProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [error, setError] = useState<ServerErrorType | undefined>();

  const addError = useCallback((error: any) => {
    setError(error);
  }, []);

  const clearError = useCallback(() => {
    setError(undefined);
    window.removeEventListener('focus', clearError);
  }, []);

  const { pathname } = useLocation();

  const [userInfo] = useSyncStorage('userInfo');

  useEffect(() => {
    if (document.hidden) {
      window.addEventListener('focus', clearError);
    }
    return () => {
      window.removeEventListener('focus', clearError);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  useEffect(() => {
    clearError();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return <ErrorContext.Provider value={{ error, addError, clearError }}>{children}</ErrorContext.Provider>;
};

export const useServerError = () => {
  const { addError } = useContext(ErrorContext);

  return useCallback(
    (error: ServerErrorType | any) => {
      const e = typeof error === 'number' ? error : error?.response?.status;
      addError(Object.values(ServerErrorType).includes(e) ? (e as ServerErrorType) : ServerErrorType.ErrorDefault);
    },
    [addError]
  );
};
