import { TCellComponent } from 'components/Table';
import { ShowMore } from 'components/ShowMore';
import { HistoryLink } from 'components/HistoryLink';
import { AppRoute, routes, routes as r } from 'domain/routes';
import { FC, Fragment, useCallback, useEffect, useMemo, useRef } from 'react';
import config from 'config/index';
import { FieldType, TEntityName } from 'lib';
import { ParticipationTypeMask } from 'config/EntityMetadata/email';
import { useMetaData } from 'lib/hooks';
import { useLocation } from 'react-router-dom';
import { devLog } from 'lib/helpers';
import ToolTip from 'components/Tooltip';
import { toDisplayDate } from 'lib/adapter';
import { ReactComponent as Alert } from './alert.svg';

const phoneIsValid = (v: string) => /^1\d{10}$/.test(v);
const formatPhone = (v: string) =>
  v.replace(/(?<country>\d{1,2})(?<code>\d{3})(?<n1>\d{3})(?<n2>\d{4})$/, '+$<country> ($<code>) $<n1>-$<n2>');

export const PhoneComponent: TCellComponent<any> = ({ data, name, defaultValue }) => {
  if (!data[name]) return <>{defaultValue}</>;
  return phoneIsValid(data[name]) ? (
    <a href={`callto:${data[name]}`}>{formatPhone(data[name])}</a>
  ) : (
    <div
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <Alert />
      <span
        style={{
          color: 'var(--textLighter2)',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          width: '100%',
        }}
      >
        {data[name]}
      </span>
    </div>
  );
};

export const EmailComponent: TCellComponent<any> = ({ data, name, defaultValue }) => {
  if (!data[name]) return <>{defaultValue}</>;
  return <a href={`mailto:${data[name]}`}>{data[name]}</a>;
};

export const UrlComponent: TCellComponent<any> = ({ data, name, defaultValue }) => {
  if (!data[name]) return <>{defaultValue}</>;
  return (
    <a href={data[name]} target="_blank" rel="noreferrer">
      {data[name]}
    </a>
  );
};

export const HTMLComponent =
  (overrideName?: string, maxHeight = 60, toolTip = false): TCellComponent<any> =>
  (props) => {
    const { data, name, classes, context, defaultValue } = props;
    if (context === 'TABLE') {
      if (toolTip) {
        const Component = ToolTipTextComponent(overrideName || name);
        return <Component {...props} />;
      }
      return <div>{data[overrideName || name] || defaultValue}</div>;
    }
    return data[name] ? (
      <ShowMore maxHeight={maxHeight} showOnDescTop>
        <div className={classes.ckeditor} dangerouslySetInnerHTML={{ __html: data[name] as string }} />
      </ShowMore>
    ) : (
      <>{defaultValue}</>
    );
  };

export const HTMLEmailComponent = (overrideName?: string, maxHeight = 60): TCellComponent<any> => {
  return ({ data, name, context, defaultValue }) => {
    const modifyLinks = (document: Document) => {
      document.querySelectorAll('a').forEach((link) => {
        link.setAttribute('target', '_blank');
        const href = link.getAttribute('href');
        if (href && !/^(http|https|mailto|tel):/.test(href)) {
          link.setAttribute('href', 'http://' + href);
        }
      });
    };

    return context === 'TABLE' ? (
      <div>{data[overrideName || name] || defaultValue}</div>
    ) : data[name] ? (
      <ShowMore maxHeight={maxHeight} showOnDescTop>
        <iframe
          srcDoc={data[name]}
          width={'100%'}
          style={{ borderWidth: '0px' }}
          onLoad={(event) => {
            const contentHeight = event.currentTarget.contentWindow?.document.body.scrollHeight;
            if (contentHeight) event.currentTarget.height = `${contentHeight + 32}px`;
            modifyLinks(event.currentTarget.contentWindow?.document as Document);
          }}
        />
      </ShowMore>
    ) : (
      <>{defaultValue}</>
    );
  };
};

export const AreaTooltipComponent: TCellComponent<any> = (props) => {
  const { data, name, context, classes, defaultValue = '---' } = props;
  const Component = ToolTipTextComponent();
  if (context === 'TABLE' && data[name]) return <Component {...props} />;
  return data[name] ? (
    <ShowMore showOnDescTop>
      <div className={classes.lineBreak}>{data[name] || defaultValue}</div>
    </ShowMore>
  ) : (
    <>{defaultValue}</>
  );
};

export const AreaComponent: TCellComponent<any> = ({ data, name, context, classes, defaultValue = '---' }) => {
  if (context === 'TABLE') return <div>{data[name] || defaultValue}</div>;
  return data[name] ? (
    <ShowMore showOnDescTop>
      <div className={classes.lineBreak}>{data[name] || defaultValue}</div>
    </ShowMore>
  ) : (
    <>{defaultValue}</>
  );
};

export const ToolTipTextComponent =
  (overrideName?: string): TCellComponent<any> =>
  ({ data, name, defaultValue }) => {
    const __html = overrideName ? data[overrideName] || data[overrideName.split('.')[1]] : data[name];
    if (data[name]) {
      return (
        <ToolTip content={<div style={{ overflowX: 'hidden' }} dangerouslySetInnerHTML={{ __html }} />}>
          {data[name]}
        </ToolTip>
      );
    }
    return <>{defaultValue}</>;
  };

export const ToolTipEmailComponent =
  (overrideName?: string): TCellComponent<any> =>
  ({ data, name, defaultValue }) => {
    const __html = overrideName ? data[overrideName] || data[overrideName.split('.')[1]] : data[name];
    if (data[name]) {
      return (
        <ToolTip
          content={
            <div style={{ overflowX: 'hidden' }}>
              <iframe
                srcDoc={__html}
                width={'100%'}
                style={{ borderWidth: '0px' }}
                onLoad={(event) => {
                  const contentHeight = event.currentTarget.contentWindow?.document.body.scrollHeight;
                  if (contentHeight) event.currentTarget.height = `${contentHeight + 32}px`;
                  const contentWidth = event.currentTarget.contentWindow?.document.body.scrollWidth;
                  if (contentWidth) event.currentTarget.width = `${contentWidth + 32}px`;
                }}
              />
            </div>
          }
        >
          {data[name]}
        </ToolTip>
      );
    }
    return <>{defaultValue}</>;
  };

export const DateOnlyComponent: TCellComponent<any> = ({ data, name, defaultValue }) =>
  data[name] ? toDisplayDate(data[name], true).slice(0, 10) : defaultValue;

export const PartyListComponent = (partyAllocation?: {
  targetEntityName: string;
  participationTypeMask: ParticipationTypeMask;
}) => {
  const Component: TCellComponent<any> = ({ data, name, context, defaultValue = '---' }) => {
    const itemsCount = useMemo(() => data[name]?.length, [data, name]);

    const links = useCallback(
      (data: string[]) =>
        data.length
          ? data.map((item: string, index) => {
              const [entity, id, name, email] = item.split('<|>');
              const entityConfig = Object.keys(config).find((item) => config[item as TEntityName].name === entity);
              const route = Object.entries(r).find(([key]) => key === entityConfig);

              return (
                <span key={name}>
                  {(route && <HistoryLink to={(route[1] as AppRoute<{ id: string }>)({ id })}>{name}</HistoryLink>) ||
                    (id === 'undefined' ? <a href={`mailto:${email}`}>{email}</a> : name)}
                  {index + 1 === itemsCount ? '' : ', '}
                </span>
              );
            })
          : defaultValue,
      [defaultValue, itemsCount]
    );

    const tableLinks = useCallback(
      (partyAllocation: Record<string, any>) => {
        const items = (data[`${partyAllocation.targetEntityName}_activity_parties`] || []).filter(
          (item: any) => item.participationtypemask === partyAllocation.participationTypeMask
        );

        return items.length
          ? items.map((item: any, index: number) => {
              const entity = item['_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname'];
              const id = item['_partyid_value'];
              const name = item['_partyid_value@OData.Community.Display.V1.FormattedValue'];
              const entityConfig = Object.keys(config).find((item) => config[item as TEntityName].name === entity);
              const route = Object.entries(r).find(([key]) => key === entityConfig);
              return (
                <Fragment key={id}>
                  {(route && <HistoryLink to={(route[1] as AppRoute<{ id: string }>)({ id })}>{name}</HistoryLink>) ||
                    (id === undefined ? <a href={`mailto:${item.addressused}`}>{item.addressused}</a> : name)}
                  {index + 1 === items.length ? '' : ', '}
                </Fragment>
              );
            })
          : defaultValue;
      },
      [data, defaultValue]
    );

    return context === 'TABLE' ? (
      <>{partyAllocation ? tableLinks(partyAllocation) : defaultValue}</>
    ) : (
      <>{itemsCount > 0 ? <ShowMore>{links(data[name] as [])}</ShowMore> : defaultValue}</>
    );
  };
  return Component;
};

export const PolymorphicComponent: TCellComponent<any> = ({ data, name, defaultValue }) => {
  const key = useMemo(() => (name.includes('.') ? name : `_${name}_value`), [name]);
  const link = useCallback(
    (data: Record<string, any>) => {
      const record = data[key];
      const recordName = data[`${key}@OData.Community.Display.V1.FormattedValue`];
      const entity = data[`${key}@Microsoft.Dynamics.CRM.lookuplogicalname`];
      const [id] = (Array.isArray(record) ? record[0] : record).split('<|>').slice(-1);

      const entityConfig = Object.keys(config).find((item) => config[item as TEntityName].name === entity);
      const route = Object.entries(r).find(([key]) => key === entityConfig);

      return (
        <>
          {(route && <HistoryLink to={(route[1] as AppRoute<{ id: string }>)({ id })}>{recordName}</HistoryLink>) ||
            recordName}
        </>
      );
    },
    [key]
  );

  return <>{data[key] ? link(data) : defaultValue}</>;
};

export const LinkComponent =
  (route?: AppRoute<any>, from?: string): TCellComponent<any> =>
  ({ data, name, defaultValue }) => {
    const fieldName = name.includes('.') ? name : `_${name}_value`;
    const lookupLogicalName = data[`${fieldName}@Microsoft.Dynamics.CRM.lookuplogicalname`];
    const routeFromData = Object.keys(config).find((key) => config[key as TEntityName].name === lookupLogicalName);
    const calculatedRoute = (route || routes[routeFromData as keyof typeof routes]) as AppRoute<{
      id: string | number;
    }>;
    return (data as Record<string, string>)[fieldName] && calculatedRoute ? (
      <HistoryLink to={calculatedRoute({ id: (data as Record<string, string>)[fieldName] || 0 })} from={from}>
        {(data as Record<string, string>)[`${fieldName}@OData.Community.Display.V1.FormattedValue`]}
      </HistoryLink>
    ) : (
      <>{(data as Record<string, string>)[`${fieldName}@OData.Community.Display.V1.FormattedValue`] || defaultValue}</>
    );
  };

export const useComponents = (entityName: TEntityName) => {
  const { getFieldType, getLookupRoute } = useMetaData(entityName);
  const { pathname } = useLocation();

  const getDefaultComponent = useCallback(
    (name: string): TCellComponent<any> | undefined => {
      switch (getFieldType(name)) {
        case FieldType.Lookup:
          return LinkComponent(getLookupRoute(name) as AppRoute<any>, pathname);
        case FieldType.Owner:
          return PolymorphicComponent;
        default:
          return;
      }
    },
    [getFieldType, getLookupRoute, pathname]
  );

  return { getDefaultComponent };
};

export const propsDebugger =
  <T extends Record<string, any>>(WrappedComponent: FC<T>, name: string) =>
  (props: T) => {
    devLog('RENDER ', name);
    const ref = useRef(props as Record<string, any>);
    useEffect(() => {
      const diff = Object.entries(props).filter(([key, value]) => ref.current[key] !== value);
      if (diff.length > 0) {
        devLog(name, ' Updated Keys:', diff.map((v) => v[0]).join(', '), '.');
      }
      ref.current = props;
    }, [props]);

    return <WrappedComponent {...props} />;
  };

export const RawComponent: TCellComponent<any> = ({ data, name, defaultValue }) => data[name] || defaultValue;
