import defaultClasses from './table.module.css';
import { FC, ReactNode } from 'react';
import { Input } from 'components/Form';
import { FieldType } from 'lib';
import { Direction } from 'components/Table/hooks';

type Classnames = keyof typeof defaultClasses;

type Classes = Record<Classnames, string>;

export type WithClasses = { classes: Classes };

export type TCellComponent<Data extends Record<string, any>> = FC<
  {
    data: Data;
    name: string;
    context?: string;
    defaultValue: string;
  } & WithClasses
>;

export type TAdapter<T = Record<string, any>> = (data: T, name: string, defaultValue: string) => string | number;

export type TConfig<Data extends Record<string, any>> = {
  name: string;
  label?: string | JSX.Element;
  description?: string;
  type: FieldType;
  editable?: boolean;
  isRequired?: ((values: Record<string, any>, data: Record<string, any> | undefined) => boolean) | boolean;
  isDisabled?: ((values: Record<string, any>, data: Record<string, any> | undefined) => boolean) | boolean;
  searchable?: boolean;
  hideFilters?: boolean;
  sortable?: boolean;
  component?: TCellComponent<Data>;
  adapter: TAdapter<Data>;
  field?: typeof Input;
  fieldRenderer?: (props: { value?: string | number; name: string; context?: string }) => JSX.Element;
  fieldProps?: (props: {
    classes: Record<string, string>;
    values: Record<string, any>;
    initialValues?: Record<string, any>;
    context?: string;
  }) => Record<string, any>;
  headerComponent?: FC<{ config: TConfig<any> }>;
  hiddenForTable?: boolean;
  hiddenForView?: boolean;
  filterAs?: string;
  excludeFromListQuery?: boolean;
  textViewField: string;
};

export type TCellProps<Data extends Record<string, any>> = {
  data: Data;
  component?: TCellComponent<Data>;
  name: string;
  editable?: boolean;
  edit?: boolean;
  field?: typeof Input;
  fieldRenderer?: (props: { value: string | number; name: string; context?: string }) => JSX.Element;
  classes: Classes;
  width: number;
  defaultValue: string;
  value: string | number;
  sticky?: boolean;
  context?: string;
};

export type TRowComponent = FC<
  {
    children?: ReactNode;
    onClick?: () => void;
    onDoubleClick?: () => void;
    selected?: boolean;
  } & WithClasses
>;

export type TRowProps<Data extends Record<string, any>> = {
  data: Data;
  columns: string[];
  Cell: FC<TCellProps<Data>>;
  CellComponent?: TCellComponent<Data>;
  config: Record<string, TConfig<Data>>;
  edit?: boolean;
  RowComponent: TRowComponent;
  onSubmit?: (data: Record<string, any>) => void;
  classes: Classes;
  cellSizes: Record<string, number>;
  defaultValue: string;
  selectionProps?: {
    selected: boolean;
    toggle: () => void;
  };
  pinnedColumns: string[];
  onRowDblClick?: () => void;
  selectionType: SelectionType;
  context: string;
};

type BaseTableProps<Data extends Record<string, any>> = {
  data: Data[];
  config: Record<string, TConfig<Data>>;
  columns: string[];
  classes?: Classes;
  onSubmit?: (data: Record<string, any>) => void;
  fixed?: boolean;
  cellSizes: Record<string, number>;
  onCellResize?: (name: string, width: number) => void;
  pinnedColumns?: string[];
  pinColumn?: (name: string) => void;
  defaultValue?: string;
  selected?: number[];
  setSelected?: (v: number[]) => void;
  placeholder?: JSX.Element;
  onRowDblClick?: (data: Data) => void;
  tableName: string;
  uniqueKey?: string;
} & Components<Data>;

export type ThType = {
  name: string;
  width: number;
  onCellResize?: (name: string, width: number) => void;
  isPinned: boolean;
  pinColumn?: (name: string) => void;
  sortDirection?: 'asc' | 'desc';
  sortIndex?: number;
  sortByName: (direction?: Direction) => void;
  clearByName: (clearOther?: boolean) => void;
  removeFilter?: () => void;
  clearOtherFilters?: () => void;
  config: TConfig<any>;
  filterConfig: TConfig<any>;
  tableName: string;
  showControls?: boolean;
  isMultiSorting?: boolean;
} & WithClasses;

type HeaderComponents = {
  TheadComponent: FC<{ children: ReactNode } & WithClasses>;
  Th: (props: ThType) => JSX.Element | null;
  cellSizes: Record<string, number>;
  onCellResize?: (name: string, width: number) => void;
  pinnedColumns: string[];
  pinColumn?: (name: string) => void;
  showControls?: boolean;
};

export type THeadProps<Data extends Record<string, any>> = {
  columns: string[];
  config: Record<string, TConfig<Data>>;
  classes: Classes;
  cellSizes: Record<string, number>;
  onCellResize?: (name: string, width: number) => void;
  selectionProps?: {
    selected: boolean;
    //all: boolean;
    toggle: () => void;
    selectionType: SelectionType;
  };
  isEmpty?: boolean;
  tableName: string;
  showControls?: boolean;
} & HeaderComponents;

export type TBodyComponents<Data extends Record<string, any>> = {
  TBodyComponent: FC<{ children: ReactNode } & WithClasses>;
  Cell: (props: TCellProps<Data>) => JSX.Element;
  Row: (props: TRowProps<Data>) => JSX.Element;
  RowComponent: TRowComponent;
  CellComponent?: TCellComponent<Data>;
};

export type TBodyProps<Data extends Record<string, any>> = {
  config: Record<string, TConfig<Data>>;
  columns: string[];
  data: Data[];
  classes: Classes;
  onSubmit?: (data: Record<string, any>) => void;
  cellSizes: Record<string, number>;
  defaultValue: string;
  selectionProps?: {
    selected: number[];
    toggle: (value: number) => void;
  };
  pinnedColumns: string[];
  onRowDblClick?: (data: Data) => void;
  selectionType: SelectionType;
  context: string;
  uniqueKey?: string;
} & TBodyComponents<Data>;

export enum SelectionType {
  OPTION,
  CHECK_BOX,
}

type Components<Data extends Record<string, any>> = {
  // With Logic
  THead?: (props: THeadProps<Data>) => JSX.Element | null;
  TBody?: (props: TBodyProps<Data>) => JSX.Element;
  Cell?: (props: TCellProps<Data>) => JSX.Element;
  Row?: (props: TRowProps<Data>) => JSX.Element;
  // Display
  Table?: FC<WithClasses>;
  selectionType?: SelectionType;
  showControls?: boolean;
  context?: string;
  fixedRows?: boolean;
} & Partial<HeaderComponents> &
  Partial<TBodyComponents<Data>>;

export type TableProps<Data extends Record<string, any>> = BaseTableProps<Data> & Components<Data>;
