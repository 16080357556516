import { useApi } from '../api';
import { useCallback } from 'react';
import { FetchQuery } from 'lib';

export const useListRecords = <T extends Record<string, string>>(url: string, columns: T) => {
  const { request } = useApi();

  const prepareQuery = useCallback((query: FetchQuery, attributesToAdd: string[]) => {
    const newQuery = { fetch: {} as typeof query.fetch };
    Object.assign(newQuery.fetch, query.fetch);
    newQuery.fetch._attributes = {};
    newQuery.fetch.entity = {};
    Object.assign(newQuery.fetch._attributes, query.fetch._attributes);
    Object.assign(newQuery.fetch.entity, query.fetch.entity);

    delete newQuery.fetch._attributes.page;
    delete newQuery.fetch._attributes.count;
    delete newQuery.fetch.entity.order;

    newQuery.fetch.entity.attribute = attributesToAdd.filter((x) => x).map((name) => ({ _attributes: { name } }));

    return newQuery;
  }, []);

  const columnNames = Object.values(columns);

  return useCallback(
    async (data: Record<string, any>[] | FetchQuery) => {
      if (!(data instanceof Array)) {
        const query = prepareQuery(data, columnNames);
        const response = await request<{ value: Record<string, any>[] }>({ url, query });
        data = response.data.value;
      }

      return data.map((x) => {
        const record = {} as {
          [Property in keyof T]: any;
        };

        for (const [key, value] of Object.entries(columns)) {
          record[key as keyof T] = x[value];
        }

        return record;
      });
    },
    [columns, url, columnNames, prepareQuery, request]
  );
};
