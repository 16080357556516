const fields = [
  'bahai_dateofbirth',
  'bahai_email',
  'bahai_firstname',
  'bahai_gendercode',
  'bahai_lastname',
  'bahai_phone',
  'bahai_note',
  'bahai_nickname',
  'bahai_addressline',
  'bahai_city',
  'bahai_stateid',
  'bahai_zipcode',
  'bahai_phoneinput',
  'bahai_additionalemail',
  'bahai_homeuniversitystate',
  'bahai_international',
  'bahai_internationalcountry',
] as const;

const readOnly = [
  'bahai_id',
  'bahai_name',
  'bahai_age',
  'bahai_profileimage',
  'createdon',
  'createdby',
  'ownerid',
  'bahai_statuscode',
  'bahai_statusnote',
  'bahai_isgpregionid',
  'bahai_lastpersonalmedicalconditions',
  'bahai_unitid',
  'bahai_yearscompleted',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
