import entities from 'config';
import { config as personConfig } from 'schemas/person';
import { AdditionalConfigType, FormConfigGetter } from 'lib';
import { TLinkEntity } from 'components/ListPage';

type Keys = (typeof entities.participant.columns)[number];

type PersonKeys = `person.${(typeof entities.person.columns)[number]}`;

export const defaultMobileColumns: Array<Keys | PersonKeys> = [
  'bahai_personid',
  'person.bahai_phone',
  'bahai_attendancestatus',
];

export const links = {
  person: entities.person.columns,
  session: entities.session.columns,
} as TLinkEntity;

export const columns = [
  ...entities.participant.columns,
  ...entities.person.columns.map((column) => `person.${column}`),
];

export const config: AdditionalConfigType<Keys | 'bahai_eventparticipant_imsid' | PersonKeys> = {
  bahai_personid: {
    searchable: true,
  },
  bahai_sessionid: {
    hiddenForTable: true,
  },
  ...Object.fromEntries(
    Object.entries(personConfig).map(([key, props]) => ['person.' + key, { ...props, searchable: false }])
  ),
  'person.bahai_name': {
    hiddenForTable: true,
  },
  ...Object.fromEntries(entities.session.columns.map((key) => ['session.' + key, { hiddenForTable: true }])),
};

export const getFormConfig: FormConfigGetter<Keys> = () => [];
