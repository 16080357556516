import ListPage from 'components/ListPage';
import * as config from 'schemas/person';
import { ReactNode } from 'react';
import { ApiFilter, TEntityName } from 'lib';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';

const options = (meta.meta as unknown as Record<TEntityName, TAppEntityMeta>).person.fields.bahai_yearscompleted
  .options;

const quickFilters: Array<{ label: ReactNode; filter: ApiFilter }> = options.map(([value, label]) => ({
  label,
  filter: { condition: [{ attribute: 'bahai_yearscompleted', operator: 'contain-values', value: [value] }] },
}));

export const PersonList = () => <ListPage entityName="person" {...{ quickFilters, ...config }} />;
