import Header, { HEADER_CONTEXT } from 'components/ListPage/components/Header';
import { FC, ReactNode, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { AdditionalConfigType, FormConfigGetter, Privilege, TEntityName } from 'lib';
import {
  Form as PopupForm,
  FormConfigType,
  TActionControlsProps,
  TSubmitProps,
} from 'components/ListPage/components/Form';
import { useImage, useSinglePageApi } from 'components/SinglePage/hooks';
import { NavLink, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { useMetaData } from 'lib/hooks';
import classes from './singlePage.module.scss';
import { devLog, useTableConfig } from 'lib/helpers';
import { FieldValidator, FormApi } from 'final-form';
import { FormProps } from 'react-final-form';
import { Loader } from 'components/Loader';
import cx from 'classnames';
import { TLinkEntity } from 'components/ListPage';
import { AppRoute, mathRouteName, routes, TRouteName } from 'domain/routes';
import { RemoveModal } from 'components/RemoveModal';
import { Control } from 'components/Panel';
import { Action, ActionContext, HeaderAction, useActions } from 'components/Actions';
import { parseError } from 'lib/errorParser';
import { useNotifications } from 'providers/NotificationsProvider';
import { TConfig } from 'components/Table';
import { Image } from 'components/Image';
import { AlertBar } from 'components/AlertBar';
import { useSecurity } from 'providers/AuthProvider';
import { useApi } from 'domain/api';
import { AssignModal } from 'components/AssignModal';
import { TabConfig, TabsPanel } from 'components/SinglePage/components/TabsPanel';
import { useServerError } from 'providers/ErrorProvider';
import { IconButton } from 'components/IconButton';
import { ReactComponent as CollapseIcon } from './icons/collapse.svg';
import { ReactComponent as ExpandIcon } from './icons/expand.svg';
import { ReactComponent as EmailIcon } from './icons/email.svg';
import { ReactComponent as PhoneIcon } from './icons/phone.svg';
import { ReactComponent as DragIcon } from './icons/drag.svg';
import { ReactComponent as ChevronUpIcon } from './icons/chevronUp.svg';
import { ReactComponent as ArrowUpIcon } from './icons/arrow.svg';
import { ReactComponent as ZoomInIcon } from './icons/zoomIn.svg';
import { ReactComponent as ZoomOutIcon } from './icons/zoomOut.svg';
import { ReactComponent as SettingsIcon } from './icons/settings.svg';
import skeleton from './icons/skeleton.svg';
import { useDrag, useDrop } from 'react-dnd';
import { ScreenContext } from 'providers/ScreenProvider';

export type Tab = {
  tab: TEntityName;
  suffix?: string;
  label: string;
  content: (data: Record<string, any>, reload: () => void) => JSX.Element;
  isDefault?: boolean;
};

export type TSinglePage = {
  config?: AdditionalConfigType<string>;
  getFormConfig: FormConfigGetter<string>;
  getDetailsConfig?: FormConfigGetter<string>;
  validate?: FormProps['validate'];
  validation?: Partial<Record<string, FieldValidator<any>>>;
  renderHeader?: (data: Record<string, any>) => string | JSX.Element;
  FormImprover?: () => JSX.Element | null;
  links?: TLinkEntity;
  entityName: TEntityName;
  getActionControls?: (props: TActionControlsProps) => Control[];
  onSubmit?: (props: TSubmitProps) => Promise<any>;
  additionalTabs?: Array<Tab>;
  displayEdit?: boolean;
  displayRemove?: boolean;
  displayAssign?: boolean;
  isNotEditable?: (data: Record<string, any>) => JSX.Element | false;
  isActive: (data: Record<string, any>) => boolean;
  isNotRemovable?: (data: Record<string, any>) => JSX.Element | boolean | string;
  preSaveUpdate?: (data: Record<string, any>) => Record<string, any>;
  isConfirmationMessageNeeded?: (data: Record<string, any>) => boolean;
  isConfirmationMessageRequired?: (data: Record<string, any>) => boolean;
  getActions?: (baseActions: Action[]) => Array<Action>;
  getLockMessage?: (data: Record<string, any>) => {
    message?: string | JSX.Element;
    isWarning?: boolean;
    forceDisplay?: boolean;
    styles?: Record<string, any>;
  };
  WarningsImprover?: FC<{ setWarnings: (warnings: Array<string | JSX.Element>) => void }>;
  formHelper?: JSX.Element;
  hideBahaiId?: boolean;
  getCommonFields?: (data: Record<string, any>) => string[];
  getContactFields?: (data: Record<string, any>) => string[];
  getTitleFields?: (data: Record<string, any>) => string[];
  getStatusFields?: (data: Record<string, any>) => string[];
  extra?: (data: Record<string, any>) => JSX.Element | null;
  mainSectionName?: ReactNode;
  allowDraft?: boolean;
};

export const getLockMessageDefault = () => ({ message: <Trans>Record State is Inactive</Trans> });

const DragTab = ({
  index,
  blockHeader,
  move,
  expanded = false,
  displayIndex,
}: {
  index: number;
  displayIndex: number;
  blockHeader: ReactNode;
  move: (first: number, second: number) => void;
  expanded?: boolean;
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'TAB',
    item: () => ({ index, blockHeader }),
    collect: (monitor) => {
      return {
        isDragging: monitor.isDragging(),
      };
    },
  }));

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'TAB',
    drop: (item: { index: number }) => {
      if (item && item.index !== index) move(item.index, displayIndex);
    },
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      };
    },
  }));

  return (
    <div
      className={cx(classes.blockWrapper, { [classes.over]: isOver && !isDragging, [classes.expanded]: expanded })}
      ref={drag}
    >
      <div className={cx(classes.dataBlock, { [classes.drop]: canDrop })} ref={drop}>
        <div className={cx(classes.dataHeader)}>
          {blockHeader}
          <div className={classes.icons}>
            <DragIcon className={classes.dragIcon} />
          </div>
        </div>
        <div className={classes.skeleton} style={{ backgroundImage: `url(${skeleton})` }} />
      </div>
    </div>
  );
};

const DataTab = ({
  index,
  lastIndex,
  fields,
  config,
  data,
  blockHeader,
  move,
  zoom,
  zoomed = false,
  collapse,
  collapsed = false,
  displayIndex,
}: {
  index: number;
  displayIndex: number;
  lastIndex: number;
  blockHeader: ReactNode;
  fields: string[];
  data: Record<string, any>;
  config: Record<string, TConfig<Record<string, any>>>;
  move: (first: number, second: number) => void;
  zoom: (index: number) => void;
  zoomed?: boolean;
  collapse: (index: number) => void;
  collapsed?: boolean;
}) => {
  const { isMobile, isDescTop } = useContext(ScreenContext);

  const onMoveUp = useCallback(() => move(index, displayIndex - 1), [displayIndex, index, move]);
  const onMoveDown = useCallback(() => move(index, displayIndex + 1), [displayIndex, index, move]);

  const onZoom = useCallback(() => zoom(index), [index, zoom]);
  const onCollapse = useCallback(() => collapse(index), [collapse, index]);

  return (
    <div className={cx(classes.blockWrapper, { [classes.expanded]: zoomed })}>
      <div className={classes.dataBlock} key={fields.join()}>
        <div className={cx(classes.dataHeader)}>
          {blockHeader}
          <div className={classes.icons}>
            {isDescTop && <IconButton iconOnly Icon={zoomed ? ZoomOutIcon : ZoomInIcon} onClick={onZoom} />}

            {!isDescTop && !!lastIndex && (
              <IconButton disabled={displayIndex === 0} iconOnly Icon={ArrowUpIcon} onClick={onMoveUp} />
            )}
            {!isDescTop && !!lastIndex && (
              <IconButton
                disabled={displayIndex === lastIndex}
                className={classes.flip}
                iconOnly
                Icon={ArrowUpIcon}
                onClick={onMoveDown}
              />
            )}

            {isMobile && (
              <IconButton
                className={cx({ [classes.flip]: collapsed })}
                iconOnly
                Icon={ChevronUpIcon}
                onClick={onCollapse}
              />
            )}
          </div>
        </div>
        {(!collapsed || !isMobile) &&
          fields
            .filter((name) => config?.[name]?.hiddenForView !== true)
            .map((name) => (config[name] ? config[name] : devLog('No config for ' + name, config)))
            .filter((v): v is TConfig<Record<string, any>> => !!v)
            .map(({ name, fieldProps = () => null, label, description, component: Rc }) => (
              <div
                key={name}
                className={
                  fieldProps({
                    classes,
                    values: data,
                    context: 'SINGLE_PAGE',
                  })?.className
                }
              >
                <div className={classes.label}>{description || label}</div>
                <div className={classes.value}>
                  {Rc ? (
                    <Rc data={data} name={name} defaultValue="---" classes={classes} />
                  ) : (
                    config[name].adapter(data, name, '---')
                  )}
                </div>
              </div>
            ))}
      </div>
    </div>
  );
};

const loadIndexes = (key: string, defaultValue = [] as number[]): number[] => {
  try {
    const json = localStorage.getItem(key);
    if (json && json.length > 0) {
      const indexes = JSON.parse(json);
      if (Array.isArray(indexes)) {
        if (defaultValue.length !== indexes.length) {
          return defaultValue as number[];
        }
        return defaultValue.length
          ? (indexes.filter((v) => defaultValue.includes(v)) as number[])
          : (indexes as number[]);
      }
      return defaultValue;
    }
  } catch (e) {
    devLog(e);
    return defaultValue;
  }
  return defaultValue;
};

export const DataBlock = ({
  viewConfig,
  data,
  config,
  dragMode = false,
  entityName,
}: {
  viewConfig: FormConfigType<any>;
  data: Record<string, any>;
  config: Record<string, TConfig<Record<string, any>>>;
  hideBahaiId?: boolean;
  dragMode?: boolean;
  entityName: TEntityName;
}) => {
  const [indexes, setIndexes] = useState<number[]>(() =>
    loadIndexes(`${entityName}-order`, [...viewConfig.keys()] as number[])
  );
  const [zoomed, setZoomed] = useState<number[]>(() => loadIndexes(`${entityName}-zoomed`, []));
  const [collapsed, setCollapsed] = useState<number[]>(() => loadIndexes(`${entityName}-collapsed`, []));

  const collapse = useCallback(
    (index: number) =>
      setCollapsed((list) => (list.includes(index) ? list.filter((v) => v !== index) : list.concat(index))),
    []
  );

  const zoom = useCallback(
    (index: number) =>
      setZoomed((list) => (list.includes(index) ? list.filter((v) => v !== index) : list.concat(index))),
    []
  );

  const move = useCallback((element: number, secondIndex: number) => {
    setIndexes((indexes) => {
      const firstIndex = indexes.indexOf(element);
      const targetElement = indexes[secondIndex];
      const newIndexes = indexes.filter((v) => v !== element);
      newIndexes.splice(newIndexes.indexOf(targetElement) + (firstIndex < secondIndex ? 1 : 0), 0, element);
      return newIndexes;
    });
  }, []);

  const filteredConfig = useMemo(
    () =>
      indexes
        .map((index) => ({ index, config: viewConfig[index] }))
        .filter(({ config: [_, fields, hideIfEmpty] }) => {
          if (dragMode) return true;
          if (typeof hideIfEmpty !== 'function') return true;
          if (!hideIfEmpty(data)) return true;
          return fields
            .filter((name) => config?.[name]?.hiddenForView !== true)
            .some((name) => config[name].adapter(data, name, '---') !== '---');
        }),
    [config, data, dragMode, indexes, viewConfig]
  );

  useEffect(() => {
    localStorage.setItem(`${entityName}-order`, JSON.stringify(indexes));
  }, [entityName, indexes]);

  useEffect(() => {
    localStorage.setItem(`${entityName}-zoomed`, JSON.stringify(zoomed));
  }, [entityName, zoomed]);

  useEffect(() => {
    localStorage.setItem(`${entityName}-collapsed`, JSON.stringify(collapsed));
  }, [collapsed, entityName]);

  return (
    <div className={cx(classes.data, { [classes.drag]: dragMode })} id="single_page_data">
      {filteredConfig.map(({ index, config: [blockHeader, fields] }, displayIndex) =>
        dragMode ? (
          <DragTab
            key={index + '-' + displayIndex + '-' + fields.join('-') + String(blockHeader)}
            index={index}
            displayIndex={displayIndex}
            blockHeader={blockHeader}
            move={move}
          />
        ) : (
          <DataTab
            key={index + fields.join('-') + String(blockHeader)}
            index={index}
            lastIndex={filteredConfig.length - 1}
            displayIndex={displayIndex}
            blockHeader={blockHeader}
            fields={fields}
            data={data}
            config={config}
            move={move}
            zoom={zoom}
            zoomed={zoomed.includes(index)}
            collapse={collapse}
            collapsed={collapsed.includes(index)}
          />
        )
      )}
    </div>
  );
};

const useTabsSettings = (entity: string, baseTabsConfig: TabConfig[] = []) => {
  const [settings, setSettings] = useState<Record<string, TabConfig[]>>(
    () => JSON.parse(localStorage.getItem('tabs') || '{}') || ({} as Record<string, TabConfig[]>)
  );

  const allOrderedTabs = useMemo(
    () =>
      baseTabsConfig
        .map((v) => v.label)
        .sort()
        .join(','),
    [baseTabsConfig]
  );

  // Filter tabs if some not Granted already
  const availableTabs = useMemo(() => baseTabsConfig.map((v) => v.label), [baseTabsConfig]);

  const tabs: TabConfig[] = useMemo(
    () => settings[entity]?.filter((v) => availableTabs.includes(v.label)) || baseTabsConfig,
    [availableTabs, baseTabsConfig, entity, settings]
  );

  const storedTabsNames = useMemo(() => tabs.map((v) => v.label), [tabs]);

  const savedOrderedTabs = useMemo(() => storedTabsNames.sort().join(','), [storedTabsNames]);

  const update = useCallback(
    (config: TabConfig[]) => {
      setSettings((v) => ({
        ...v,
        [entity]: config,
      }));
    },
    [entity]
  );

  useEffect(() => {
    if (savedOrderedTabs !== allOrderedTabs) {
      const newTabs = tabs.filter((v) => availableTabs.includes(v.label));
      const names = newTabs.map((v) => v.label);
      baseTabsConfig.forEach((v, index) => {
        if (!names.includes(v.label)) {
          newTabs.splice(index, 0, { ...v });
        }
      });
      update(newTabs);
    }
  }, [allOrderedTabs, availableTabs, baseTabsConfig, savedOrderedTabs, storedTabsNames, tabs, update]);

  useEffect(() => {
    localStorage.setItem('tabs', JSON.stringify(settings));
  }, [settings]);

  const { pathname } = useLocation();

  useEffect(() => {
    const currentTab = baseTabsConfig.find(
      ({ name, label }) => pathname.endsWith('/' + name) && !tabs.find((v) => v.label === label)?.visible
    );
    if (currentTab) {
      update(tabs.map((v) => (v.label === currentTab.label ? { ...v, visible: true } : v)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tabs, update };
};

export const FieldsBlock = ({
  fields,
  config,
  data,
}: {
  fields: string[];
  config: Record<string, TConfig<Record<string, any>>>;
  data: Record<string, any>;
}) => {
  const getLabel = useCallback((name: string, label: ReactNode) => {
    if (name.includes('email')) return <EmailIcon />;
    if (name.includes('phone')) return <PhoneIcon />;
    return label;
  }, []);

  if (fields.length === 0) return null;
  return (
    <div className={classes.fields}>
      {fields
        .map((name) => config[name])
        .map(({ name, label, component: Rc }) => (
          <div key={name} className={classes.flex}>
            <div className={classes.left}>{getLabel(name, label)}</div>
            <div className={classes.right}>
              {Rc ? (
                <Rc data={data} name={name} defaultValue="---" classes={classes} context={`STATUS_BLOCK`} />
              ) : (
                config[name].adapter(data, name, '---')
              )}
            </div>
          </div>
        ))}
    </div>
  );
};

export const SinglePage = ({
  config: additionalConfig = {},
  getFormConfig,
  getDetailsConfig,
  renderHeader,
  links,
  entityName,
  getActionControls,
  onSubmit,
  additionalTabs = [],
  isNotEditable = () => false,
  displayEdit = true,
  displayRemove = true,
  isActive = () => true,
  isNotRemovable = () => false,
  isConfirmationMessageNeeded = () => false,
  isConfirmationMessageRequired = () => true,
  getActions = (v) => v,
  getLockMessage = getLockMessageDefault,
  displayAssign = false,
  formHelper: helper,
  getTitleFields = () => [],
  getStatusFields = () => [],
  getCommonFields = () => [],
  getContactFields = () => [],
  hideBahaiId = false,
  extra,
  mainSectionName = <Trans>Profile Info</Trans>,
  ...formProps
}: TSinglePage) => {
  const {
    push,
    location: { pathname, state: locationState },
  } = useHistory<{ from: string }>();
  const { tab } = useParams<{ tab?: string }>();

  const { isMobile, isDescTop } = useContext(ScreenContext);

  const from = useRef('/');

  useEffect(() => {
    if (locationState?.from) from.current = locationState.from;
    devLog(`Come from "${from.current}"`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { addActionCompleted, addActionFailed, addActionUncompleted } = useNotifications();
  const [privileges, setPrivileges] = useState([] as Privilege[]);

  const { getACL } = useApi();

  const [edit, setEdit] = useState(false);
  const closeEdit = useCallback(() => setEdit(false), [setEdit]);

  const {
    url,
    entityConfig,
    displayName,
    hiddenFields,
    PrimaryNameAttribute,
    PrimaryImageAttribute,
    OwnershipType,
    ObjectTypeCode,
  } = useMetaData(entityName);
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();

  const routeName = useMemo(() => mathRouteName(pathname) as TRouteName, [pathname]);

  const config = useTableConfig(entityConfig.fields, additionalConfig, entityName, links);
  const { reload, data, initialValues, remove, removeWithConfirmation, loading, query } = useSinglePageApi({
    id,
    links,
    entityName,
  });

  useEffect(() => {
    getACL(id, url, OwnershipType, ObjectTypeCode).then(setPrivileges);
  }, [ObjectTypeCode, OwnershipType, getACL, id, url, data._ownerid_value]);

  const [removeVisible, setRemoveVisible] = useState(false);
  const closeRemove = useCallback(() => setRemoveVisible(false), [setRemoveVisible]);

  const objectTitle = useMemo(() => ` ${data[PrimaryNameAttribute] || data.bahai_id}`, [PrimaryNameAttribute, data]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  const removeHeader = useMemo(
    () =>
      data ? (
        <Trans>
          Do you want to delete
          <strong className={classes.strong}>{objectTitle}</strong>? This is an irreversible action
        </Trans>
      ) : undefined,
    [data, objectTitle]
  );
  const addServerError = useServerError();

  const onRemove = (formData: { Message?: string }, form: FormApi<{ Message: string }>) => {
    const { valid } = form.getState();
    if (valid)
      (formData.Message ? removeWithConfirmation(formData.Message, data) : remove(data))
        .then(() => {
          addActionCompleted(t('{{displayName}} was deleted', { displayName }));
          push(from.current || '/');
        })
        .catch((e) => {
          if (e.response?.status === 404) {
            addServerError(e.response?.status);
          } else {
            addActionFailed(parseError(e));
            setRemoveVisible(false);
          }
        });
  };

  const onUpdate = useCallback(() => {
    reload().then(() => {
      setEdit(false);
    });
  }, [reload]);

  const viewConfig: FormConfigType<string> = useMemo(
    () =>
      (getDetailsConfig || getFormConfig)(data || {}, true).map(([label, fields, hideEmptyFn = () => false]) => [
        label,
        fields.filter((v) => !hiddenFields.includes(v)),
        hideEmptyFn,
      ]),
    [getDetailsConfig, getFormConfig, data, hiddenFields]
  );

  const [assignVisible, setAssignVisible] = useState(false);
  const closeAssign = useCallback(
    (needReload?: boolean) => {
      setAssignVisible(false);
      if (needReload) reload().then();
    },
    [reload]
  );

  const baseActions = useActions({
    onReload: reload,
    onRemove: ({ selectedItems: [data] }) => {
      if (isNotRemovable(data)) {
        addActionUncompleted(isNotRemovable(data) as JSX.Element);
      } else {
        setRemoveVisible(true);
      }
    },
    onAssign: ({ selectedItems: [data] }) => {
      if (isNotEditable(data)) {
        addActionUncompleted(isNotEditable(data) as JSX.Element);
      } else {
        setAssignVisible(true);
      }
    },
    onEdit: ({ selectedItems: [data] }) => {
      if (isNotEditable(data)) {
        addActionUncompleted(isNotEditable(data) as JSX.Element);
      } else {
        setEdit(true);
      }
    },
  });

  const actions: Array<HeaderAction> = useMemo(
    () =>
      getActions(baseActions)
        .filter(
          ({ display = () => true }) =>
            /*type === ActionType.COMMON || type === ActionType.CREATE ||*/ !loading &&
            display({
              isCreateAllowed: false,
              isRemoveAllowed: displayRemove && privileges.includes(Privilege.Delete),
              isEditAllowed: displayEdit && privileges.includes(Privilege.Write),
              isAssignAllowed: !!displayAssign && privileges.includes(Privilege.Assign),
              data: data || {},
              context: ActionContext.SinglePage,
              entityName,
              selectedItems: [data || {}],
            })
        )
        .map((props) => ({
          ...props,
          onClick: () =>
            props.onClick({
              context: ActionContext.SinglePage,
              selectedItems: [data || {}],
              query,
              reload,
            }),
        })),
    [
      getActions,
      baseActions,
      loading,
      displayRemove,
      privileges,
      displayEdit,
      displayAssign,
      data,
      entityName,
      query,
      reload,
    ]
  );

  const imageProps = useImage(entityName, data);
  const { forceDisplay, ...lockProps } = getLockMessage(data);

  const { isGranted } = useSecurity();
  const statusFields = useMemo(
    () =>
      getStatusFields(data)
        .map((name) => config[name].adapter(data, name, '---'))
        .join(' • '),
    [config, data, getStatusFields]
  );

  const baseTabsConfig: TabConfig[] = useMemo(
    () =>
      (additionalTabs || [])
        .filter(({ tab }) => isGranted(tab, Privilege.Read) || tab === 'historylog')
        .map(({ label, tab, isDefault: visible }) => ({ name: tab, label, visible })),
    [additionalTabs, isGranted]
  );

  const [mainCollapsed, setMainCollapsed] = useState(!!tab);
  const toggleMain = useCallback(() => setMainCollapsed((v) => !v), []);

  useEffect(() => {
    !!tab && setMainCollapsed(true);
    setDragMode(false);
  }, [tab]);

  const { tabs, update: updateTabsConfig } = useTabsSettings(entityName, baseTabsConfig);

  const setTabsConfig = useCallback(
    (tabsConfig: TabConfig[]) => {
      updateTabsConfig(tabsConfig);
      setTabsSettingsVisible(false);
    },
    [updateTabsConfig]
  );

  const visibleTabs: Tab[] = useMemo(
    () =>
      tabs
        .filter((v) => v.visible)
        .map(({ label }) => additionalTabs?.find((v) => v.label === label))
        .filter((v) => !!v) as Tab[],
    [additionalTabs, tabs]
  );

  const [tabsSettingsVisible, setTabsSettingsVisible] = useState(false);
  const toggleTabsSettings = useCallback(() => setTabsSettingsVisible((v) => !v), []);

  const headerTitle: ReactNode = useMemo(() => {
    if (renderHeader) return renderHeader(data);
    if (getTitleFields(data).length)
      return getTitleFields(data)
        .map((key) => data[key + '@OData.Community.Display.V1.FormattedValue'] || data[key])
        .join(' ');
    return data[PrimaryNameAttribute];
  }, [PrimaryNameAttribute, data, getTitleFields, renderHeader]);

  const [dragMode, setDragMode] = useState(false);
  const toggleDragMode = useCallback(() => setDragMode((v) => !v), []);

  // can be changed to any or all actions if needed
  useEffect(() => {
    if (!loading && window.location.hash === '#edit') {
      window.history.replaceState({}, document.title, window.location.href.split('#')[0]);
      document.getElementById('action_edit')?.click();
    }
  }, [loading]);

  const imageNotEditable = useMemo(() => {
    if (isNotEditable(data)) return isNotEditable(data);
    if (!displayEdit || !privileges.includes(Privilege.Write)) return t("You don't have permissions");
  }, [data, displayEdit, isNotEditable, privileges, t]);

  if (loading)
    return (
      <div className={classes.loader}>
        <Loader />
      </div>
    );

  if (!data) return null;

  return (
    <div className={classes.root} id="single_page_root">
      {edit && (
        <PopupForm
          onClose={closeEdit}
          postAction={onUpdate}
          config={config}
          context="FORM_EDIT"
          data={data}
          {...{ id, getFormConfig, initialValues, getActionControls, onSubmit, entityName, helper }}
          {...formProps}
        />
      )}
      {tabsSettingsVisible && (
        <TabsPanel
          initialConfig={baseTabsConfig}
          setTabsConfig={setTabsConfig}
          tabs={tabs}
          onClose={() => setTabsSettingsVisible(false)}
        />
      )}
      {removeVisible && (
        <RemoveModal
          header={removeHeader}
          onClose={closeRemove}
          {...{
            isConfirmationMessageNeeded: isConfirmationMessageNeeded(data),
            isConfirmationMessageRequired: isConfirmationMessageRequired(data),
            onRemove,
            entityName: displayName,
          }}
        />
      )}
      {assignVisible && <AssignModal entityName={entityName} onClose={closeAssign} id={id} />}
      {(!isActive(data || {}) || forceDisplay) && <AlertBar {...lockProps} />}
      <div className={classes.header}>
        <Header headerContext={HEADER_CONTEXT.FORM} actions={actions} />
      </div>
      <div className={cx(classes.content, { [classes.drag]: dragMode && isDescTop })}>
        <div className={classes.tabBtnWrapper}>
          {[{ label: t('General') } as Tab, ...visibleTabs].map(({ label, tab, suffix = '' }) => (
            <NavLink
              to={(routes[routeName] as AppRoute<{ id: string; tab?: string }>)({
                id,
                tab: tab ? tab + suffix : undefined,
              })}
              exact
              key={label}
              className={classes.tabBtn}
              activeClassName={classes.selected}
            >
              {label}{' '}
            </NavLink>
          ))}

          {additionalTabs?.length > 0 && (
            <button
              className={cx(classes.tabBtn, { [classes.selected]: tabsSettingsVisible })}
              onClick={toggleTabsSettings}
            >
              {t('More')}
            </button>
          )}
          {isDescTop && !tab && viewConfig.length > 1 && (
            <IconButton
              className={cx(classes.settings, { [classes.dragMode]: dragMode })}
              Icon={SettingsIcon}
              onClick={toggleDragMode}
            >
              {t('Drag Mode')}
            </IconButton>
          )}
        </div>
        <div className={classes.wrapContent}>
          <div className={cx(classes.headerBox, { [classes.mainCollapsed]: mainCollapsed })}>
            <div className={classes.mainTitle}>
              <div className={classes.title}>
                {mainSectionName} {mainCollapsed ? ' • ' : ''}
              </div>
              {mainCollapsed && <div className={classes.record}>{headerTitle}</div>}
            </div>
            <IconButton
              iconOnly
              className={classes.collapse}
              Icon={mainCollapsed ? ExpandIcon : CollapseIcon}
              onClick={toggleMain}
            />
            <div className={classes.blockToHide}>
              <div className={classes.infoWrapper}>
                {PrimaryImageAttribute && (
                  <Image
                    {...imageProps}
                    isNotEditable={imageNotEditable}
                    lettersPlaceholder={`${data.bahai_firstname?.[0] || ''}` + `${data.bahai_lastname?.[0] || ''}`}
                  />
                )}
                <div className={classes.rightBlock}>
                  <div className={classes.mainSection}>{headerTitle}</div>
                  {statusFields && <div className={classes.statusFields}>{statusFields}</div>}
                  {!hideBahaiId && data.bahai_id && <div className={classes.bahaiId}>{data.bahai_id}</div>}
                </div>
              </div>
              <FieldsBlock fields={getContactFields(data)} config={config} data={data} />
              <FieldsBlock fields={getCommonFields(data)} config={config} data={data} />
              {extra && extra(data)}
            </div>
          </div>
          <div className={cx(classes.rightContent, { [classes.tab]: !!tab })}>
            <Switch>
              <Route path={(routes[routeName] as AppRoute<{ id: string }>)({ id })} exact>
                <DataBlock
                  dragMode={dragMode && !isMobile}
                  key={Number(mainCollapsed)}
                  viewConfig={viewConfig}
                  data={data}
                  config={config}
                  entityName={entityName}
                />
              </Route>
              {visibleTabs.map(({ tab, suffix = '', content }) => (
                <Route
                  key={tab}
                  path={(routes[routeName] as AppRoute<{ id: string; tab?: string }>)({
                    id,
                    tab: tab ? tab + suffix : undefined,
                  })}
                  exact
                >
                  <div className={classes.tabsWrapper}>{content(data, reload)}</div>
                </Route>
              ))}
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SinglePage;
