import { Trans } from 'react-i18next';
import { FieldState, FieldValidator } from 'final-form';
import { isFuture, isPast, isBefore } from 'date-fns';
import { parseDate } from 'lib/adapter';
import { isGreaterDay } from 'components/DateTimePicker/Calendar';
import { DRAFT_FIELD_NAME } from 'components/ListPage/components/Form';

export const compose =
  (validators: Array<FieldValidator<unknown>>): FieldValidator<unknown> =>
  (...args) => {
    for (const fn of validators) {
      if (fn(...args)) return fn(...args);
    }
  };

const isEmailValid = (v: unknown) =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    `${v}`
  );

export const notCurrentId = (message: string | JSX.Element) => (value: any, values: Record<string, any>, meta: any) =>
  meta?.data?.id && value && value === meta.data?.id ? message : undefined;

export const isEmpty = (value: any) => {
  switch (true) {
    case typeof value === 'string':
      return value.trim().replace('<div/>', '').length === 0;
    case Array.isArray(value):
      return value.length === 0;
    case typeof value === 'number':
      return isNaN(value);
    case typeof value === 'boolean':
      return false;
    default:
      return !value;
  }
};

const isRequired = (value: unknown, values: Record<string, any>, message = <Trans>Required</Trans>) => {
  if (values && values[DRAFT_FIELD_NAME]) return undefined;
  return isEmpty(value) ? message : undefined;
};

const RequiredJSX = <Trans>Required</Trans>;

export const required = (value: any, values?: Record<string, any>) => {
  if (values && values[DRAFT_FIELD_NAME]) return undefined;
  return isEmpty(value) ? RequiredJSX : undefined;
};

export const groupRequired = (fields: string[]) => (value: any, values: Record<string, any>) =>
  fields.some((field) => !isEmpty(values[field])) ? required(value) : undefined;

export const doesNotMatch =
  (field: string, message: string | JSX.Element): FieldValidator<unknown> =>
  (value, values: Record<string, any>) => {
    return value && values[field] === value ? message : undefined;
  };

export const conditionalRule =
  (fn: (values: Record<string, any>) => boolean, rule: FieldValidator<unknown>): FieldValidator<unknown> =>
  (value, values, ...rest) =>
    fn(values) ? rule(value, values, ...rest) : undefined;

export const conditionalRequired =
  (fn: (values: Record<string, any>) => boolean, message = RequiredJSX): FieldValidator<unknown> =>
  (value, values) =>
    fn(values) ? isRequired(value, values, message) : undefined;

export const relatedRequired =
  (related: string[], message: string | JSX.Element = RequiredJSX): FieldValidator<any> =>
  (value, values) =>
    related.some((key) => values[key as keyof typeof values]) ? undefined : message;

export const maxLength = (max: number) => (value: unknown) =>
  `${value}`.trim().length > max ? <Trans>Max Length is {{ max }} symbols</Trans> : undefined;

export const maxRichTextLength = (max: number) => (value: unknown) => {
  const length = ((value as string) || '').replace(/\<.+?\>/g, '').replaceAll('&nbsp;', ' ').length;
  return length > max ? <Trans>Max Length is {{ max }} symbols</Trans> : undefined;
};

export const minLength = (min: number) => (value: unknown) =>
  `${value}`.trim().length < min ? <Trans>Min Length is {{ min }} symbols</Trans> : undefined;

export const maxLengthEscapeTags = (max: number) => (value: unknown) =>
  `${value}`
    .replaceAll(/\<.+?\>/g, '')
    .replaceAll('&nbsp;', ' ')
    .trim().length > max ? (
    <Trans>Max Length is {{ max }} symbols</Trans>
  ) : undefined;

export const alternativeEmail = (primaryEmail: string) => (value: any, values: Record<string, any>) => {
  return value && value === values[primaryEmail] ? <Trans>Should not be the same as Email</Trans> : undefined;
};

export const length = (min: number, max: number) => (value: unknown) =>
  `${value}`.trim().length > max || `${value}`.trim().length < min ? (
    <Trans>
      Length should be between {{ min }} and {{ max }} symbols
    </Trans>
  ) : undefined;

export const email = (v: unknown) => (!v || isEmailValid(v) ? undefined : <Trans>Invalid Email</Trans>);
export const phone = (v: unknown) => {
  return !v || /\d{11}/.test(`${v}`) ? undefined : <Trans>Invalid Phone format (XXX) XXX-XXXX</Trans>;
};

export const pastDate = (v: unknown) =>
  !v || typeof v !== 'string' || !isGreaterDay(parseDate(v, false), new Date()) ? undefined : (
    <Trans>Can't be in the future</Trans>
  );

export const futureDate = (v: unknown) =>
  !v || typeof v !== 'string' || !isGreaterDay(new Date(), parseDate(v, false)) ? undefined : (
    <Trans>Can't be in the past</Trans>
  );

export const futureTime = (v: unknown) =>
  !v || typeof v !== 'string' || isFuture(parseDate(v, true)) ? undefined : <Trans>Can't be in the past</Trans>;

export const pastTime = (v: unknown) =>
  !v || typeof v !== 'string' || isPast(parseDate(v, true)) ? undefined : <Trans>Can't be in the future</Trans>;

export const lessThanDate =
  (fn: (values: Record<string, any>) => string, message: JSX.Element): FieldValidator<unknown> =>
  (value, values) =>
    !value ||
    typeof value !== 'string' ||
    !fn(values) ||
    !isBefore(parseDate(value, value.length > 10), parseDate(fn(values), value.length > 10))
      ? undefined
      : message;

export const futureDateDirtyOnly = (v: unknown, values: Record<string, any>, meta?: FieldState<unknown>) =>
  meta?.dirty ? futureDate(v) : undefined;

export const futureDateTimeDirtyOnly = (v: unknown, values: Record<string, any>, meta?: FieldState<unknown>) =>
  meta?.dirty ? futureTime(v) : undefined;

export const confirmation = compose([required, length(2, 256)]);

export const numericRange = (minValue: number, maxValue: number) => (v: unknown) => {
  if (!/\d/.test(String(v))) return;
  if (Number(v) < minValue || Number(v) > maxValue)
    return (
      <Trans>
        Value should be between {{ minValue }} and {{ maxValue }}
      </Trans>
    );
};

export const valueRange = (minValue: number, maxValue: number) => (v: unknown) => {
  return String(v).length > 0 && (String(v).length < minValue || String(v).length > maxValue) ? (
    <Trans>
      Value should be between {{ minValue }} and {{ maxValue }}
    </Trans>
  ) : undefined;
};

export const year = numericRange(1950, 2100);
