import SinglePage from 'components/SinglePage';
import * as config from 'schemas/seminar';
import { Trans, useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ApiFilter, TEntityName } from 'lib';
import { useCallback, useMemo, useState } from 'react';
import { useApi } from 'domain/api';
import meta from 'domain/meta.json';
import { TAppEntityMeta } from 'dynamics-meta';
import * as fullSessionConfig from 'schemas/session';
import ListPage from 'components/ListPage';
import { Select } from 'components/Form';

const { config: sessionConfig, ...restSessionConfig } = fullSessionConfig;

const {
  session: { url, fields },
} = meta.meta as unknown as Record<TEntityName, TAppEntityMeta>;

export const Seminar = () => {
  const { t } = useTranslation();
  const { id = '' } = useParams<{ id: string }>();
  const { findOneBy } = useApi();
  const hiddenFilters: ApiFilter[] = useMemo(
    () => [{ condition: [{ operator: 'eq', attribute: 'bahai_seminarid', value: id }] }],
    [id]
  );

  const [options, setOptions] = useState(new Map<string, string>());

  const getInitialValues = useCallback(async () => ({ bahai_seminarid: id }), [id]);

  const isNotCreatable = useCallback(async () => {
    const {
      data: { value },
    } = await findOneBy(url, 'bahai_yearoffered', '_bahai_seminarid_value', id);
    const usedYears = value.map((v) => String(v.bahai_yearoffered));
    const unusedOptions = fields.bahai_yearoffered.options.filter((v) => !usedYears.includes(v[0]));
    if (unusedOptions.length > 0) {
      setOptions(new Map<string, string>(unusedOptions));
      return unusedOptions.length === 1 ? { bahai_yearoffered: unusedOptions[0][0] } : {};
    } else {
      throw new Error(t('This Seminar has Sessions created for all 4 Years Offered'));
    }
  }, [findOneBy, id, t]);

  const updatedSessionConfig = useMemo(
    () => ({
      ...sessionConfig,
      bahai_yearoffered: {
        ...sessionConfig.bahai_yearoffered,
        field: (props: any) => <Select {...props} items={options} />,
      },
    }),
    [options]
  );

  return (
    <SinglePage
      entityName="seminar"
      displayEdit={true}
      displayRemove={true}
      mainSectionName={<Trans>Basic Information</Trans>}
      additionalTabs={[
        {
          label: t('Sessions'),
          tab: 'session',
          isDefault: true,
          content: (_: Record<string, any>, reload) => (
            <ListPage
              entityName="session"
              getInitialValues={getInitialValues}
              hiddenFilters={hiddenFilters}
              isSubgrid={true}
              isRemoveHidden={false}
              isNotCreatable={isNotCreatable}
              onItemCreated={reload}
              onItemsRemoved={reload}
              config={updatedSessionConfig}
              {...restSessionConfig}
            />
          ),
        },
      ]}
      {...config}
    />
  );
};
