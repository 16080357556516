import { ApiFilter } from 'lib';
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import classes from './quickFilters.module.scss';
import cx from 'classnames';
import { useHistory } from 'react-router-dom';
import { unifyPathName } from 'providers/TableProvider';

export const useQuickFilters = (filters?: Array<{ label: ReactNode; filter: ApiFilter }>) => {
  const {
    action,
    location: { pathname },
  } = useHistory();

  const key = ['quick-filters', unifyPathName(pathname)].filter((v) => !!v).join('/');

  const [index, setIndex] = useState(() =>
    localStorage.getItem(key) && action === 'POP' ? Number(localStorage.getItem(key)) : -1
  );

  useEffect(() => {
    localStorage.setItem(key, String(index));
  }, [index, key]);

  const onClick = useCallback((i: number) => setIndex((v) => (v === i ? -1 : i)), []);

  const filter: ApiFilter[] = useMemo(
    () => (filters?.[index] ? [filters?.[index].filter] : ([] as ApiFilter[])),
    [filters, index]
  );

  const content = useMemo(
    () =>
      filters ? (
        <div className={classes.root}>
          {filters.map(({ label }, i) => (
            <button
              className={cx(classes.button, { [classes.active]: i === index })}
              onClick={() => onClick(i)}
              key={`${i}-${index}`}
            >
              {label}
            </button>
          ))}
        </div>
      ) : null,
    [filters, index, onClick]
  );

  return { filter, content };
};
