import { MouseEventHandler, useCallback, useContext, useMemo, useState } from 'react';
import classes from './view.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as PinIcon } from './icons/pin.svg';
import { ReactComponent as EditIcon } from './icons/edit.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as Icon } from './icons/icon.svg';
import cx from 'classnames';
import { Button } from 'components/Button';
import Tooltip from 'components/Tooltip';
import { View } from 'components/ListPage/hook';
import { ViewForm, ViewFormData } from 'components/ListPage/components/ViewForm';
import { MenuButton } from 'components/MenuButton';
import { Modal } from 'components/Modal';
import { ScreenContext } from 'providers/ScreenProvider';
import { useViewActions } from 'providers/TableProvider/hooks';
import { TEntityName } from 'lib';
import { TableContext } from 'providers/TableProvider';
import { UserSettingsContext } from 'providers/UserSettingsProvider';

// TODO move calls with ID to parent component

const ViewComponent = ({
  id,
  name,
  pinView,
  onEdit,
  onRemove,
  selected,
  pinned,
  isSystem,
  description,
  elementId,
}: View & {
  onEdit: (id: string) => void;
  onRemove: (id: string) => void;
  pinned: boolean;
  pinView: (id: string) => void;
  selected: boolean;
  elementId?: string;
}) => {
  const { t } = useTranslation();
  const { setCurrentView } = useContext(TableContext);

  const pin = useCallback(() => pinView(id), [pinView, id]);
  const editView = useCallback(() => onEdit(id), [id, onEdit]);
  const removeView = useCallback(() => onRemove(id), [id, onRemove]);
  const select: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      if (e.target === e.currentTarget) setCurrentView(id);
    },
    [setCurrentView, id]
  );

  const tooltipContent = useMemo(() => <div>{description || t('No Description for this view')}</div>, [description, t]);

  return (
    <div onClick={select} className={cx(classes.item, { [classes.selected]: selected })} id={elementId}>
      <Tooltip content={tooltipContent}>
        <div className={cx(classes.iconWrapper, 'tool-tip')}>
          <InfoIcon />
        </div>
      </Tooltip>
      <div className={classes.title}>{name}</div>
      {!isSystem && (
        <button onClick={editView}>
          <EditIcon />
        </button>
      )}
      <button onClick={pin}>
        <PinIcon className={cx({ [classes.pinned]: pinned })} />
      </button>
      {!isSystem && (
        <button onClick={removeView}>
          <DeleteIcon />
        </button>
      )}
    </div>
  );
};

export const ViewList = ({
  onSave,
  entityName,
  ...baseProps
}: {
  onEdit: (id: string) => void;
  onSave: () => void;
  onRemove: (id: string) => void;
  entityName: TEntityName;
}) => {
  const { t } = useTranslation();

  const { views, currentView } = useContext(TableContext);
  const { pinView } = useViewActions(entityName);

  const system = useMemo(
    () =>
      Object.values(views)
        .filter((v) => v.isSystem)
        .sort((a: View, b: View) => a.name.localeCompare(b.name)),
    [views]
  );
  const custom = useMemo(
    () =>
      Object.values(views)
        .filter((v) => !v.isSystem)
        .sort((a: View, b: View) => a.name.localeCompare(b.name)),
    [views]
  );

  const { defaultViews } = useContext(UserSettingsContext);
  return (
    <div className={classes.root} id="viewList">
      {system.length > 0 && <div className={classes.header}>{t('System Views')}</div>}
      {system.map((props, index) => (
        <ViewComponent
          selected={currentView === props.id}
          key={props.id}
          pinned={defaultViews[entityName] === props.id}
          pinView={pinView}
          {...props}
          {...baseProps}
          elementId={index === 0 ? `first_system_view` : undefined}
        />
      ))}
      {custom.length > 0 && <div className={classes.header}>{t('Custom Views')}</div>}
      <div className={cx(classes.list, 'dontHideOnScroll')}>
        {custom.map((props) => (
          <ViewComponent
            selected={currentView === props.id}
            key={props.id}
            pinView={pinView}
            pinned={defaultViews[entityName] === props.id}
            {...props}
            {...baseProps}
          />
        ))}
      </div>
      <Button className={classes.btn} role="primary" onClick={onSave} id="button_view_save">
        {t('Save Current View')}
      </Button>
    </div>
  );
};

export const ViewsButton = ({
  classes: parentClasses,
  entityName,
}: {
  classes: Record<string, string>;
  entityName: TEntityName;
}) => {
  const { t } = useTranslation();
  const { isDescTop } = useContext(ScreenContext);
  const { updateView, updateViewById, removeViewById } = useViewActions(entityName);
  const { views, currentView, hasChanges } = useContext(TableContext);

  const [editViewDialogVisible, setEditViewDialogVisible] = useState(false);
  const [updateViewDialogVisible, setUpdateViewDialogVisible] = useState<'new' | 'update' | false>();
  const [editViewId, setEditViewId] = useState<string | undefined>();

  const [viewActionLoading, setViewActionLoading] = useState(false);

  const saveView = useCallback(
    (data?: ViewFormData, isNew = false) => {
      setViewActionLoading(true);
      if (editViewId) {
        updateViewById(editViewId, data ? { bahai_name: data.name, bahai_description: data.description } : {}).finally(
          () => {
            setViewActionLoading(false);
            setEditViewId(undefined);
          }
        );
      } else {
        updateView(data ? { bahai_name: data.name, bahai_description: data.description } : undefined, isNew).finally(
          () => {
            setViewActionLoading(false);
            setUpdateViewDialogVisible(false);
            setEditViewDialogVisible(false);
          }
        );
      }
    },
    [editViewId, updateViewById, updateView]
  );

  const onRemoveView = useCallback(
    (id: string) => {
      removeViewById(id);
      if (editViewId) setEditViewId(undefined);
      if (updateViewDialogVisible !== false) setUpdateViewDialogVisible(false);
    },
    [removeViewById, editViewId, updateViewDialogVisible]
  );

  const view = useMemo(() => views[currentView], [currentView, views]);

  const onUpdateViewClick = useCallback(() => {
    view?.isSystem ? setUpdateViewDialogVisible('new') : setEditViewDialogVisible(true);
  }, [view?.isSystem]);

  const [removeId, setRemoveId] = useState<string>();
  const acceptRemove = useCallback(
    (id: string) => {
      setRemoveId(undefined);
      onRemoveView(id);
    },
    [onRemoveView]
  );

  const title = useMemo(
    () => (
      <div className={cx(classes.titleWrapper)} id="button_view">
        {t('View by: {{ name }}', { name: view?.name || '' })}
      </div>
    ),
    [t, view?.name]
  );

  return (
    <>
      <MenuButton
        className={cx(parentClasses.viewBtn, { [classes.withChanges]: hasChanges })}
        title={title}
        iconOnly={!isDescTop}
        Icon={Icon}
      >
        <ViewList onSave={onUpdateViewClick} onRemove={setRemoveId} onEdit={setEditViewId} entityName={entityName} />
      </MenuButton>
      {editViewDialogVisible && (
        <Modal
          loading={viewActionLoading}
          title={t('Update Current View?')}
          header={t('You can create a new View from this table configuration or update the current View.')}
          onClose={() => setEditViewDialogVisible(false)}
          controls={[
            {
              title: t('Update'),
              role: 'primary',
              onClick: () => {
                setViewActionLoading(true);
                saveView();
              },
            },
            {
              title: t('Create New'),
              onClick: () => {
                setEditViewDialogVisible(false);
                setUpdateViewDialogVisible('new');
              },
            },
          ]}
        />
      )}
      {(updateViewDialogVisible || editViewId) && (
        <ViewForm
          initialValues={
            updateViewDialogVisible === 'update' || editViewId
              ? {
                  name: (editViewId ? views[editViewId] : view || { name: '' }).name,
                  description: (editViewId ? views[editViewId] : view || {}).description || '',
                }
              : undefined
          }
          loading={viewActionLoading}
          onFormSubmit={(data: ViewFormData) => saveView(data, updateViewDialogVisible === 'new')}
          onClose={() => (editViewId ? setEditViewId(undefined) : setUpdateViewDialogVisible(false))}
        />
      )}
      {removeId && (
        <Modal
          onClose={() => setRemoveId(undefined)}
          title={t('Delete View?')}
          controls={[
            { title: t('Delete'), onClick: () => acceptRemove(removeId), role: 'primary' },
            {
              title: t('Cancel'),
              onClick: () => setRemoveId(undefined),
            },
          ]}
        >
          <div>
            <Trans>
              Do you want to delete <strong>{views[removeId]?.name}</strong>? This is an irreversible action
            </Trans>
          </div>
        </Modal>
      )}
    </>
  );
};
