import cx from 'classnames';
import classes from 'components/ListPage/listPage.module.scss';
import DataTable, { TableProps } from 'components/Table';
import { useCallback, useContext, useMemo } from 'react';
import { ScreenContext } from 'providers/ScreenProvider';
import { ReactComponent as Check } from 'components/ListPage/icons/check.svg';
import { useMetaData } from 'lib/hooks';
import { TEntityName } from 'lib';
import { useHistory } from 'react-router-dom';
import { TableContext } from 'providers/TableProvider';

type ListTableProps = {
  data: Record<string, any>[];
  loading?: boolean;
  entityName: TEntityName;
  selected?: number[];
  toggleSelect?: (index: number) => void;
  getMobileTitle?: (data: Record<string, any>) => string;
  allowRowClick?: boolean;
  className?: string;
  context?: string;
  showControls?: boolean;
  fixedRows?: boolean;
  defaultMobileColumns?: string[];
} & Omit<TableProps<Record<string, any>>, 'columns' | 'tableName' | 'cellSizes' | 'pinColumn' | 'onCellResize'>;

const getMobileTitleDefault = <Data extends Record<string, any>>(data: Data) => {
  return [data.bahai_firstname, data.bahai_lastname].map((v) => (v || '').slice(0, 1)).join('');
};

export const ListTable = ({
  data,
  loading,
  entityName,
  selected = [],
  toggleSelect,
  placeholder,
  config,
  getMobileTitle = getMobileTitleDefault,
  allowRowClick = true,
  className,
  context = 'TABLE',
  defaultMobileColumns,
  ...tableProps
}: ListTableProps) => {
  const { PrimaryIdAttribute, PrimaryImageAttribute } = useMetaData(entityName);
  const { isMobile } = useContext(ScreenContext);
  const { push } = useHistory();

  const { columnsConfig, isCustomView, setColumnsConfig } = useContext(TableContext);

  const getImageUrl = useCallback(
    (data: Record<string, any>) => {
      if (!PrimaryImageAttribute || !data) return;
      return data[PrimaryImageAttribute] ? 'data:image/jpg;base64,' + data[PrimaryImageAttribute] : undefined;
    },
    [PrimaryImageAttribute]
  );

  const mobileColumns = useMemo(
    () =>
      isCustomView || !defaultMobileColumns
        ? columnsConfig
            .filter((v) => v.visible)
            .slice(0, 3)
            .map((v) => v.name)
        : defaultMobileColumns,
    [columnsConfig, defaultMobileColumns, isCustomView]
  );

  const renderMobileListItem = useCallback(
    (item: Record<string, any>, index: number) => {
      return (
        <div
          key={item[PrimaryIdAttribute]}
          className={cx(classes.listItem, { [classes.listItemSelected]: selected.includes(index) })}
        >
          <div
            className={cx(classes.mobileRing, {
              [classes.ringCheck]: selected.length > 0,
              [classes.checked]: selected.includes(index),
            })}
            onClick={() => (toggleSelect ? toggleSelect(index) : undefined)}
          >
            {selected.includes(index) ? (
              <Check />
            ) : (
              <>{getImageUrl(item) ? <img src={getImageUrl(item)} alt="Item image" /> : getMobileTitle(item)}</>
            )}
          </div>
          <div
            className={classes.listItemContent}
            onClick={(e) => {
              if ((e.target as Element).tagName !== 'A')
                allowRowClick && push(`/${entityName}/${item[PrimaryIdAttribute]}`);
            }}
          >
            {mobileColumns
              .map((key) => config[key])
              .map(({ name, component: Component, adapter }) => (
                <div key={name} className={classes.mobileLine}>
                  {Component ? (
                    <Component
                      key={name}
                      data={item}
                      name={name}
                      defaultValue="---"
                      classes={classes}
                      context={context}
                    />
                  ) : (
                    <div>{adapter(item, name, '---')}</div>
                  )}
                </div>
              ))}
          </div>
        </div>
      );
    },
    [
      PrimaryIdAttribute,
      allowRowClick,
      config,
      context,
      entityName,
      getImageUrl,
      getMobileTitle,
      mobileColumns,
      push,
      selected,
      toggleSelect,
    ]
  );

  const visibleColumns = useMemo(() => columnsConfig.filter((v) => v.visible).map((v) => v.name), [columnsConfig]);
  const pinnedColumns = useMemo(() => columnsConfig.filter((v) => v.pinned).map((v) => v.name), [columnsConfig]);
  const pinColumn = useCallback(
    (name: string) =>
      setColumnsConfig((config) => config.map((v) => (v.name === name ? { ...v, pinned: !v.pinned } : v))),
    [setColumnsConfig]
  );
  const cellSizes = useMemo(
    () => Object.fromEntries(columnsConfig.map(({ name, width }) => [name, width])),
    [columnsConfig]
  );

  const onCellResize = useCallback(
    (name: string, width: number) =>
      setColumnsConfig((config) => config.map((v) => (v.name === name ? { ...v, width } : v))),
    [setColumnsConfig]
  );

  return (
    <>
      {isMobile ? (
        loading ? (
          placeholder
        ) : (
          <div className={cx(classes.mobileList, { [classes.withSelection]: selected.length > 0 })}>
            {data.length > 0 && !loading ? data.map(renderMobileListItem) : placeholder}
          </div>
        )
      ) : (
        <div className={className}>
          <DataTable
            columns={visibleColumns}
            {...{
              cellSizes,
              classes,
              placeholder,
              data,
              selected,
              toggleSelect,
              config,
              tableName: entityName,
              context,
              uniqueKey: PrimaryIdAttribute,
              pinnedColumns,
              pinColumn,
              onCellResize,
              ...tableProps,
            }}
          />
        </div>
      )}
    </>
  );
};
