const fields = ['firstname', 'lastname', 'fullname', 'homephone', 'mobilephone', 'middlename'] as const;

const readOnly = [
  'isdisabled',
  'fullname',
  'entityimage',
  'createdby',
  'internalemailaddress',
  'businessunitid',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
