import React, { Suspense, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { routes as r, TRouteName } from 'domain/routes';
import classes from './index.module.css';
import Menu from 'components/Menu';
import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/Header';
import { SnackBarNotifications } from 'components/SnackBarNotifications';
import { ErrorProvider } from 'providers/ErrorProvider';
import { ResourceList } from './ResourceList';
import { Resource } from './Resource';
import { Privilege, TEntityName } from 'lib';
import { useSecurity } from 'providers/AuthProvider';
import { Person } from './Person';
import { PersonList } from './PersonList';
import { Application } from './Application';
import { ApplicationList } from './ApplicationList';
import { FacilitatorList } from './FacilitatorList';
import { Facilitator } from './Facilitator';
import { SeminarList } from './SeminarList';
import { Seminar } from './Seminar';
import { Session } from './Session';
import { BreadCrumbs } from 'components/BreadCrumbs';

const pages: Array<{ route: TRouteName; component: (props: any) => JSX.Element }> = [
  { route: 'personList', component: PersonList },
  { route: 'person', component: Person },
  { route: 'applicationList', component: ApplicationList },
  { route: 'application', component: Application },
  { route: 'seminarList', component: SeminarList },
  { route: 'seminar', component: Seminar },
  { route: 'session', component: Session },
  { route: 'resourceList', component: ResourceList },
  { route: 'resource', component: Resource },
  { route: 'facilitatorList', component: FacilitatorList },
  { route: 'facilitator', component: Facilitator },
];

export const getEntityNameFromRoute = (name: string) => name.replace('List', '') as TEntityName;

const App = () => {
  const { isGranted } = useSecurity();

  const allowedPages = useMemo(
    () => pages.filter(({ route }) => isGranted(getEntityNameFromRoute(route), Privilege.Read)),
    [isGranted]
  );

  return (
    <>
      <ErrorProvider>
        <ErrorBoundary>
          {(showMenu: boolean, error: JSX.Element | undefined, showHeader) => (
            <>
              {showHeader && <Header />}
              <div className={classes.wrapper}>
                {!error && <SnackBarNotifications />}
                {showMenu && showHeader && <Menu />}
                {error ? (
                  error
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    <div className={classes.page}>
                      <BreadCrumbs />
                      <Switch>
                        {allowedPages.map(({ route, ...props }) => (
                          <Route key={r[route].path as string} path={r[route].path as string} exact={true} {...props} />
                        ))}
                        <Route path="/" exact={true}>
                          <Redirect to={r.personList.path} />
                        </Route>
                      </Switch>
                    </div>
                  </Suspense>
                )}
              </div>
            </>
          )}
        </ErrorBoundary>
      </ErrorProvider>
    </>
  );
};

export { App };
