import React, { ReactNode } from 'react';
import classes from './uploadMenu.module.scss';
import { ReactComponent as UploadIcon } from './icons/upload.svg';
import { ReactComponent as DeleteIcon } from './icons/delete.svg';
import { ReactComponent as LockIcon } from './icons/nopermissions.svg';
import cx from 'classnames';

interface UploadImagesMenuProps {
  uploadImage: () => void;
  deleteImage: () => void;
  image: boolean;
  isNotEditable?: ReactNode;
}

const UploadImagesMenu: React.FC<UploadImagesMenuProps> = ({ uploadImage, deleteImage, image, isNotEditable }) => {
  return (
    <>
      {!isNotEditable ? (
        <div className={classes.container}>
          <button className={classes.button} onClick={uploadImage}>
            <UploadIcon className={classes.icon} /> Upload new picture
          </button>
          <button
            className={
              image ? cx(classes.button, classes.delete) : cx(classes.button, classes.delete, classes.disabled)
            }
            onClick={deleteImage}
          >
            <DeleteIcon className={classes.icon} /> Delete
          </button>
        </div>
      ) : (
        <div className={classes.container}>
          <button className={classes.nopermissions}>
            <LockIcon className={classes.icon} /> {isNotEditable}
          </button>
        </div>
      )}
    </>
  );
};

export default UploadImagesMenu;
