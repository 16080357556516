const fields = [
  'bahai_buildingandroomlist',
  'bahai_city',
  'bahai_facilityname',
  'bahai_finishdate',
  'bahai_leadfacilitatorid',
  'bahai_note',
  'bahai_startdate',
  'bahai_stateid',
  'bahai_streetaddress',
  'bahai_typecode',
  'bahai_zipcode',
  'bahai_calendaryear',
] as const;

const readOnly = [
  'bahai_id',
  'bahai_statuscode',
  'bahai_name',
  'bahai_unitid',
  'ownerid',
  'createdon',
  'bahai_yearsoffered',
  'bahai_isgpregionid',
] as const;

export default { fields: fields, columns: [...fields, ...readOnly] };
