import { ChangeEventHandler, useCallback } from 'react';
import inputClasses from 'components/Form/Input/input.module.scss';
import { PHONE_OUTPUT_FORMAT, TValueComponent } from 'lib';
import MaskedInput from 'react-text-mask';
import { Select } from 'components/Select';
import { devLog } from 'lib/helpers';

const phoneItems = new Map<string, JSX.Element>([['1', <>🇺🇸</>]]);

export const Input: TValueComponent<{
  inputType?: 'text' | 'area' | 'phone' | 'year' | 'number';
  className?: string;
}> = ({ inputType = 'text', value = '', onChange, className = inputClasses.input, ...props }) => {
  const onInputChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange(e.target.value || '');
    },
    [onChange]
  );

  const onPhoneChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(
    (e) => {
      onChange((e.target.value ? '1' + e.target.value : '').replace(/\D/g, ''));
    },
    [onChange]
  );

  return (
    <>
      {inputType === 'area' && (
        <textarea className={className} rows={3} onChange={onInputChange} value={value} {...props} />
      )}
      {(inputType === 'year' || inputType === 'number') && (
        <input type="number" className={className} onChange={onInputChange} value={value} {...props} />
      )}
      {inputType === 'text' && <input className={className} onChange={onInputChange} value={value} {...props} />}
      {inputType === 'phone' && (
        <div className={inputClasses.flex}>
          <Select
            isClearable={false}
            className={inputClasses.phoneSelect}
            items={phoneItems}
            value={['1']}
            onChange={devLog}
          />
          <MaskedInput
            className={className}
            mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            value={value.slice(1)}
            onChange={onPhoneChange}
            {...props}
            placeholder={PHONE_OUTPUT_FORMAT}
          />
        </div>
      )}
    </>
  );
};
