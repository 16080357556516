import { useRecord } from 'lib/record';
import { useCallback, useState, useMemo, useRef } from 'react';
import { Modal } from 'components/Modal';
import { Field, Form } from 'react-final-form';
import { Input } from 'components/Form';
import * as rules from 'lib/rules';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import { devLog } from 'lib/helpers';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as ArchiveIcon } from './icons/archive.svg';
import { ReactComponent as UnArchiveIcon } from './icons/unarchive.svg';
import { ReactComponent as RegisteredIcon } from './icons/changeToRegistered.svg';
import { useNotifications } from 'providers/NotificationsProvider';
import { ARCHIVE, REGISTERED, INVITED } from 'schemas/person';
import { Loader } from 'components/Loader';
import classes from '../../../components/Modal/modal.module.scss';
import { useLoader } from 'providers/LoaderProvider';

const validation = rules.compose([rules.required, rules.maxLength(500)]);

export const useArchiveModal = (id: string) => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord('person');
  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const close = useCallback(() => setArchiveModalOpen(false), []);
  const { showLoader, hideLoader } = useLoader();
  const { addActionCompleted } = useNotifications();

  const archive = useCallback(
    (data: Record<string, any>) => {
      setLoading(true);
      patch({ bahai_statuscode: ARCHIVE, ...data }, id)
        .then(() => {
          setLoading(false);
          addActionCompleted();
          reloadRef.current && reloadRef.current();
          close();
        })
        .finally(() => setLoading(false));
    },
    [patch, id, addActionCompleted, close]
  );

  const unArchive = useCallback(
    (reload: () => void) => {
      showLoader(t('Loading...'));
      patch({ bahai_statuscode: null }, id)
        .then(() => {
          hideLoader();
          addActionCompleted();
          reload();
        })
        .catch((e) => devLog(e))
        .finally(hideLoader);
    },
    [addActionCompleted, hideLoader, id, patch, showLoader, t]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        name: 'archive',
        title: t('Archive'),
        display: ({ data }) => Number(data.bahai_statuscode) !== ARCHIVE,
        onClick: ({ reload }) => {
          reloadRef.current = reload;
          setArchiveModalOpen(true);
        },
        Icon: ArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
      {
        name: 'unArchive',
        title: t('Unarchive'),
        display: ({ data }) => Number(data.bahai_statuscode) === ARCHIVE,
        onClick: ({ reload }) => unArchive(reload),
        Icon: UnArchiveIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 10,
      },
    ],
    [t, unArchive, setArchiveModalOpen]
  );

  const content = createPortal(
    archiveModalOpen && (
      <Form
        onSubmit={archive}
        initialValues={{}}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Modal
              title={t('Change to Archive?')}
              portal={false}
              onClose={close}
              loading={loading}
              controls={[
                { title: t('Save'), type: 'submit', role: 'primary' },
                {
                  title: t('Cancel'),
                  onClick: close,
                },
              ]}
            >
              <Field
                name="bahai_statusnote"
                component={Input}
                label={t('Status Note')}
                validate={validation}
                required
                maxLength={500}
                inputType="area"
              />
            </Modal>
          </form>
        )}
      />
    ),
    document.body
  );

  return { content, actions };
};

export const useRegisteredModal = (id: string) => {
  const { t } = useTranslation();
  const reloadRef = useRef<() => void>();
  const { patch } = useRecord('person');
  const { addActionCompleted, addActionUncompleted } = useNotifications();
  const [loading, setLoading] = useState(false);

  const changeToRegistered = useCallback(
    (id: string) => {
      setLoading(true);
      patch({ bahai_statuscode: REGISTERED }, id)
        .then(() => {
          reloadRef.current && reloadRef.current();
          addActionCompleted();
        })
        .catch((e) => {
          addActionUncompleted(
            JSON.parse(e.response.data.error.message)
              .map((v: any) => v.Message)
              .join(', ')
          );
          devLog(e);
        })
        .finally(() => setLoading(false));
    },
    [patch, addActionCompleted, addActionUncompleted]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        name: 'changeToRegistered',
        title: t('Change To Registered'),
        display: ({ data }) => Number(data.bahai_statuscode) === INVITED,
        onClick: ({ reload }) => {
          reloadRef.current = reload;
          changeToRegistered(id);
        },
        Icon: RegisteredIcon,
        type: ActionType.CUSTOM_ACTION,
        allowedDevices: AllowedDevices.All,
        actionContext: ActionContext.SinglePage,
        order: 11,
      },
    ],
    [t, changeToRegistered, id]
  );

  const content = createPortal(
    loading && (
      <div className={classes.loader}>
        <Loader />
      </div>
    ),
    document.body
  );

  return { actions, content };
};
