import ListPage, { TListPage } from 'components/ListPage';
import * as personConfig from 'schemas/person';
import { ReactNode, useCallback, useContext, useMemo, useRef, useState } from 'react';
import { Action, ActionContext, ActionType, AllowedDevices } from 'components/Actions';
import { ReactComponent as AddIcon } from 'components/Actions/icons/add.svg';
import { Dialog } from 'components/Dialog';
import classes from './invite.module.scss';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as CheckedIcon } from 'components/Table/CheckBox/icons/checked.svg';
import { ReactComponent as UncheckedIcon } from 'components/Table/CheckBox/icons/unchecked.svg';
import { ReactComponent as AttendedIcon } from './icons/attended.svg';
import { ReactComponent as NotAttendedIcon } from './icons/notAttended.svg';

import { Button } from 'components/Button';
import { removePageFromQuery, useMetaData } from 'lib/hooks';
import { NotificationType, useNotifications } from 'providers/NotificationsProvider';
import { ScreenContext } from 'providers/ScreenProvider';
import { useRecord } from 'lib/record';
import { NotificationPopup } from 'components/NotificationPopup';
import { useListRecords } from 'domain/operations';
import { useLoader } from 'providers/LoaderProvider';
import { devLog } from 'lib/helpers';

export const useAttendance = () => {
  const { t } = useTranslation();
  const { addActionCompleted } = useNotifications();
  const { showLoader, hideLoader } = useLoader();

  const { patch } = useRecord('participant');
  const { url, PrimaryIdAttribute, PrimaryNameAttribute } = useMetaData('participant');
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute, name: PrimaryNameAttribute });

  const inviteSelected = useCallback(
    async (selected: Record<string, any>[], bahai_attendancestatus: boolean) => {
      let index = 0;
      let success = 0;
      const errors: { label: string; content: ReactNode }[] = [];
      while (index < selected.length) {
        try {
          showLoader(selected.length > 1 ? `Processing ${index + 1} / ${selected.length}` : undefined);
          await patch({ bahai_attendancestatus }, selected[index].bahai_participantid);
          success++;
        } catch (error) {
          errors.push({ label: selected[index].bahai_name, content: (error as Record<string, any>).message });
        }
        index++;
      }
      hideLoader();
      return { errors, success };
    },
    [hideLoader, patch, showLoader]
  );

  const getSuccessMessage = useCallback(
    (value: boolean, all: boolean) => {
      switch (true) {
        case value && all:
          return t('Participants were marked as Attended');
        case !value && all:
          return t('Participants were marked as Not Attended');
        case value && !all:
          return t('Participant was marked as Attended');
        default:
          return t('Participant was marked as Not Attended');
      }
    },
    [t]
  );

  const invite = useCallback(
    ({
      selectedItems,
      query,
      reload,
      value,
    }: {
      selectedItems: Record<string, any>[];
      query: any;
      reload: () => void;
      value: boolean;
    }) => {
      showLoader('Processing');
      const getItems = async () =>
        selectedItems.length
          ? selectedItems
          : (await getRecords(removePageFromQuery(query))).map(({ id, name }) => ({
              bahai_participantid: id,
              bahai_name: name,
            }));
      getItems()
        .then((items) => inviteSelected(items, value))
        .then(({ success }) => {
          addActionCompleted(getSuccessMessage(value, success > 1));
          reload();
        });
    },
    [addActionCompleted, getRecords, getSuccessMessage, inviteSelected, showLoader]
  );

  const actions: Action[] = useMemo(
    () => [
      {
        title: t('Mark All Attended'),
        name: 'attendAll',
        onClick: (props) => invite({ ...props, selectedItems: [], value: true }),
        order: 23,
        Icon: AttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems, data }) => data.length > 0 && selectedItems.length === 0,
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark All Not Attended'),
        name: 'notAttendAll',
        onClick: (props) => invite({ ...props, selectedItems: [], value: false }),
        order: 23,
        Icon: NotAttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems, data }) => data.length > 0 && selectedItems.length === 0,
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark Attended'),
        name: 'attend',
        onClick: (props) => invite({ ...props, value: true }),
        order: 23,
        Icon: AttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems }) => selectedItems.some((v) => v?.bahai_attendancestatus !== true),
        alwaysKeepTitle: true,
      },
      {
        title: t('Mark Not Attended'),
        name: 'notAttend',
        onClick: (props) => invite({ ...props, value: false }),
        order: 23,
        Icon: NotAttendedIcon,
        type: ActionType.CUSTOM_ACTION,
        actionContext: ActionContext.SubGid,
        allowedDevices: AllowedDevices.All,
        display: ({ selectedItems }) => selectedItems.some((v) => v?.bahai_attendancestatus !== false),
        alwaysKeepTitle: true,
      },
    ],
    [invite, t]
  );

  return { actions };
};

export const useInviteParticipant = (bahai_sessionid: string, message?: string) => {
  const { t } = useTranslation();
  const { addNotification, addWarning, addActionUncompleted } = useNotifications();
  const [closeOnFinish, setCloseOnFinish] = useState(false);
  const toggleCloseOnFinish = useCallback(() => setCloseOnFinish((v) => !v), []);

  const { displayCollectionName, PrimaryIdAttribute, PrimaryNameAttribute, url } = useMetaData('person');

  const { isMobile } = useContext(ScreenContext);

  const getActions = useCallback(
    (baseActions: Array<Action>): Array<Action> =>
      baseActions.filter((v) => ['refresh', 'selectAll', 'unselectAll'].includes(v.name as string)),
    []
  );

  const [isVisible, setIsVisible] = useState(false);

  const show = useCallback(() => setIsVisible(true), []);
  const hide = useCallback(() => {
    if (invited.current > 0) reloadRef.current();
    invited.current = 0;
    setIsVisible(false);
  }, []);

  const invited = useRef(0);

  const [loadingText, setLoadingText] = useState('');

  const { save } = useRecord('participant');
  const getRecords = useListRecords(url, { id: PrimaryIdAttribute, name: PrimaryNameAttribute });

  const inviteSelected = useCallback(
    async (selected: Record<string, any>[]) => {
      let index = 0;
      let success = 0;
      const errors: { label: string; content: ReactNode }[] = [];
      while (index < selected.length) {
        try {
          setLoadingText(`Inviting ${index + 1} / ${selected.length}`);
          await save({ bahai_personid: selected[index].bahai_personid, bahai_sessionid, bahai_attendancestatus: null });
          success++;
        } catch (error) {
          let content = <Trans>Unknown Error</Trans>;
          try {
            content = JSON.parse((error as Record<string, any>).response.data.error.message)[0].Message;
          } catch (e) {
            devLog(e);
          }
          errors.push({ label: selected[index].bahai_name, content });
        }
        index++;
      }
      setLoadingText('');
      return { errors, success };
    },
    [bahai_sessionid, save]
  );

  const invite = useCallback(
    ({ selectedItems, query, reload }: { selectedItems: Record<string, any>[]; query: any; reload: () => void }) => {
      setLoadingText('Processing');
      const getItems = async () =>
        selectedItems.length
          ? selectedItems
          : (await getRecords(removePageFromQuery(query))).map(({ id, name }) => ({
              bahai_personid: id,
              bahai_name: name,
            }));
      getItems()
        .then(inviteSelected)
        .then(({ errors, success }) => {
          if (success > 0) {
            invited.current++;
          }
          if (errors.length === 0) {
            addNotification({
              type: NotificationType.SUCCESS,
              title: t('{{ displayCollectionName }} were added to the Session', { displayCollectionName }),
              content: t('Please continue adding persons to the Session'),
            });
          } else {
            addWarning({
              title:
                success === 0
                  ? t('{{ displayCollectionName }} were not added to the Session', {
                      displayCollectionName,
                    })
                  : t('Some {{displayCollectionName}} were not added to the Session', {
                      displayCollectionName,
                    }),
              content: (
                <>
                  {t('Please see details below.')}
                  <br />
                  <NotificationPopup
                    label={t('Show Details')}
                    header={t('Info')}
                    description={t('The {{ displayCollectionName }} listed below were not added to the Session', {
                      displayCollectionName,
                    })}
                    errors={errors}
                  />
                </>
              ),
            });
          }
          closeOnFinish && hide();
          reload();
        });
    },
    [addNotification, addWarning, closeOnFinish, displayCollectionName, getRecords, hide, inviteSelected, t]
  );

  const footer = useMemo(
    () =>
      ({
        selectedItems,
        query,
        reload,
        data,
        loading,
      }: {
        selectedItems: Record<string, any>[];
        query: any;
        data: Array<any>;
        reload: () => void;
        loading: boolean;
      }) => (
        <div className={classes.footer}>
          <div className={classes.keepWrapper}>
            <button onClick={toggleCloseOnFinish} className={classes.checkBoxWrapper} type="button">
              {closeOnFinish ? <UncheckedIcon /> : <CheckedIcon />}
            </button>
            <div className={classes.subHeader}>{t('Keep window open to continue adding participants')}</div>
          </div>
          <div className={classes.controls}>
            <Button
              disabled={data.length === 0 || loading}
              type="button"
              role="primary"
              onClick={() => invite({ selectedItems, query, reload })}
            >
              {selectedItems.length > 0 ? t('Add Selected') : t('Add All')}
            </Button>
            <Button type="button" role="flat" onClick={hide}>
              {t('Cancel')}
            </Button>
          </div>
        </div>
      ),
    [closeOnFinish, hide, invite, t, toggleCloseOnFinish]
  );

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const reloadRef = useRef(() => {});

  const action: Action = useMemo(
    () => ({
      title: displayCollectionName,
      name: 'invite',
      onClick: ({ reload }) => {
        if (message) {
          addActionUncompleted(message);
        } else {
          reloadRef.current = reload;
          show();
        }
      },
      order: 23,
      Icon: AddIcon,
      type: ActionType.CUSTOM_ACTION,
      actionContext: ActionContext.SubGid,
      allowedDevices: AllowedDevices.All,
      display: ({ selectedItems }) => selectedItems.length === 0,
      alwaysKeepTitle: false,
    }),
    [addActionUncompleted, displayCollectionName, message, show]
  );

  const tableProps: TListPage & { columns: readonly string[] } = useMemo(() => {
    return {
      hiddenFilters: [
        {
          condition: [
            {
              operator: 'null',
              entityname: 'bahai_participant',
              attribute: 'bahai_sessionid',
            },
          ],
        },
        {
          condition: [
            {
              operator: 'not-null',
              attribute: 'bahai_personid',
            },
          ],
        },
      ],
      entityName: 'person',
      getActions,
      isSubgrid: true,
      isCreateHidden: true,
      displayViews: false,
      ...personConfig,
      links: {
        participant: {
          from: PrimaryIdAttribute,
          to: PrimaryIdAttribute,
          fields: [],
          condition: [{ operator: 'eq', attribute: 'bahai_sessionid', value: bahai_sessionid }],
        },
        ...personConfig.links,
      },
    };
  }, [PrimaryIdAttribute, bahai_sessionid, getActions]);

  const content = useMemo(
    () =>
      isVisible ? (
        <Dialog
          showZoom
          loading={!!loadingText}
          loadingText={loadingText}
          className={classes.dialog}
          fullScreen={isMobile}
          onClose={hide}
          centered
          styles={{ zIndex: 4 }}
          collapsedLabel={t('Invite {{ displayCollectionName }}', {
            displayCollectionName,
          })}
        >
          <div className={classes.content}>
            <h2 className={classes.header}>{t('Invite {{displayCollectionName}}', { displayCollectionName })}</h2>
            <h3 className={classes.subHeader}>
              {t('{{ displayCollectionName }} already added to the Session are not shown in the list.', {
                displayCollectionName,
              })}
            </h3>
            <ListPage hideFullScreen dialog="person" systemView={'Default'} {...tableProps}>
              {footer}
            </ListPage>
          </div>
        </Dialog>
      ) : null,
    [displayCollectionName, footer, hide, isMobile, isVisible, loadingText, t, tableProps]
  );

  return { action, content };
};
