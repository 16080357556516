import { createContext, FC, ReactNode, useCallback, useContext, useState } from 'react';
import classes from './loader.module.scss';
import { Loader } from 'components/Loader';

const LoaderContext = createContext({} as { showLoader: (message?: string) => void; hideLoader: () => void });
export const useLoader = () => useContext(LoaderContext);

export const LoaderProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [message, setMessage] = useState('');
  const showLoader = useCallback((message?: string) => {
    message && setMessage(message);
    setLoading(true);
  }, []);
  const hideLoader = useCallback(() => {
    setLoading(false);
    setMessage('');
  }, []);

  return (
    <LoaderContext.Provider value={{ showLoader, hideLoader }}>
      {children}
      {loading && (
        <div className={classes.loader}>
          <Loader text={message} withoutLoadingText={!message} />
        </div>
      )}
    </LoaderContext.Provider>
  );
};
