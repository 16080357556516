import classes from './radioswitch.module.scss';

export const RadioSwitch = ({
  selectedValue = false,
  onChange,
  items,
  name,
}: {
  selectedValue?: boolean;
  onChange: () => void;
  items: Map<string, string | JSX.Element>;
  name: string;
}) => {
  return (
    <div className={classes.wrapper}>
      {Array.from(items).map(([value, label]) => (
        <label htmlFor={name} key={value} className={classes.label}>
          <input
            type="radio"
            checked={selectedValue.toString() === value}
            onChange={onChange}
            name={name}
            value={value}
          />
          <span>{label}</span>
        </label>
      ))}
    </div>
  );
};
