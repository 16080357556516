import { generatePath, matchPath } from 'react-router';
import config from 'config';

type RouteParamsRequired<T> = (params: T) => string;
type RouteParamsOptional<T> = (params?: T) => string;

type RouteBuilder<T = undefined> = T extends undefined ? RouteParamsOptional<T> : RouteParamsRequired<T>;

export type AppRoute<T> = RouteBuilder<T> & {
  routeName: string;
  path: string;
};

const route = <T extends Record<string, string | number>>(routeName: string, path = '/') =>
  Object.assign((params: T) => generatePath(path, params), {
    routeName,
    path,
  }) as AppRoute<T>;

export const routes = {
  resourceList: route('resourceList', '/resource'),
  resource: route<{ id: number | string; tab?: string }>('resource', '/resource/:id/:tab?'),
  personList: route('personList', '/person'),
  person: route<{ id: number | string; tab?: string }>('person', '/person/:id/:tab?'),
  applicationList: route('applicationList', '/application'),
  application: route<{ id: number | string; tab?: string }>('application', '/application/:id/:tab?'),
  facilitatorList: route('facilitatorList', '/facilitator'),
  facilitator: route<{ id: number | string; tab?: string }>('facilitator', '/facilitator/:id/:tab?'),
  seminarList: route('seminarList', '/seminar'),
  seminar: route<{ id: number | string; tab?: string }>('seminar', '/seminar/:id/:tab?'),
  session: route<{ id: number | string; tab?: string }>('session', '/session/:id/:tab?'),
};

export type TRouteName = keyof typeof routes;

export const mathRouteName = (location: string) =>
  (Object.keys(routes) as Array<TRouteName>).find((key) =>
    matchPath(location, { path: routes[key]?.path, exact: true })
  );

export const getRouteByLogicalName = (name: string) => {
  const entityName = Object.keys(config).find((key) => config[key as keyof typeof config]?.name === name);
  return entityName ? routes[entityName as keyof typeof routes] : undefined;
};
