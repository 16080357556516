import { AdditionalConfigType, ApiFilter, TEntityName } from 'lib';
import { QueryParams, TableContext, TableProvider } from 'providers/TableProvider';
import { useMetaData } from 'lib/hooks';
import { useTableConfig } from 'lib/helpers';
import { TLinkEntity } from 'components/ListPage';
import { useListPageApi } from 'components/ListPage/hook';
import { ListTable } from 'components/ListPage/components/ListTable';
import { useContext, useMemo } from 'react';
import classes from './simple-table.module.scss';
import { Loader } from 'components/Loader';
import { ReactComponent as NoDataIcon } from 'components/Table/no-data.svg';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/Table/Pagination';

export type SimpleTableProps = {
  columns: Record<string, number>;
  config: AdditionalConfigType<string>;
  entityName: TEntityName;
  hiddenFilters?: ApiFilter[];
  links?: TLinkEntity;
  ready?: boolean;
  children?: ({ reload }: { reload: () => void }) => JSX.Element;
  distinct?: boolean;
  showControls?: boolean;
  fixedRows?: boolean;
  dataMapper?: (data: Record<string, any>[]) => Record<string, any>[];
  className?: string;
};

const SimpleTableBase = ({
  columns,
  config: additionalConfig,
  entityName,
  links,
  hiddenFilters,
  ready = true,
  children,
  distinct = true,
  showControls = true,
  fixedRows = true,
  dataMapper = (data) => data,
}: SimpleTableProps) => {
  const { t } = useTranslation();
  const { entityConfig } = useMetaData(entityName);
  const config = useTableConfig(entityConfig.fields, additionalConfig, entityName, links);

  const { queryParams } = useContext(TableContext);
  const {
    data,
    reload,
    loading,
    stats: { pages, recordsOverflow, total, firstIndex, lastIndex },
  } = useListPageApi({
    config,
    ready,
    links,
    entityName,
    hiddenFilters,
    queryParams,
    distinct,
  });

  const mappedData = useMemo(() => dataMapper(data), [data, dataMapper]);

  const columnsConfig = useMemo(
    () => Object.entries(columns).map(([name, width]) => ({ name, width, visible: true, pinned: false })),
    [columns]
  );

  const placeholder = useMemo(() => {
    if (loading)
      return (
        <div className={classes.loader}>
          <Loader />
        </div>
      );
    if (data.length === 0)
      return (
        <div className={classes.loader}>
          <NoDataIcon />
          <p>{t('No data available')}</p>
        </div>
      );
  }, [loading, data.length, t]);

  return (
    <div className={classes.wrapper}>
      {children && children({ reload })}
      <div className={classes.tableWrapper}>
        <ListTable
          {...{
            data: mappedData,
            config,
            columnsConfig,
            entityName,
            placeholder,
            loading,
            showControls,
            fixedRows,
            className: classes.table,
          }}
        />
        {data.length !== 0 && (
          <div className={classes.footer}>
            {t('{{ firstIndex }} - {{lastIndex}} of {{ total }}', {
              total,
              firstIndex,
              lastIndex,
            })}
            {recordsOverflow ? '+ ' : ' '}
            <Pagination pages={pages} />
          </div>
        )}
      </div>
    </div>
  );
};

const connectProvider =
  (Component: (props: SimpleTableProps) => JSX.Element) =>
  ({ props: initialValues, ...rest }: SimpleTableProps & { props?: Partial<QueryParams> }) => {
    const baseColumnsConfig = useMemo(
      () => Object.entries(rest.columns).map(([name, width]) => ({ name, width, visible: true, pinned: false })),
      [rest.columns]
    );
    return (
      <TableProvider
        columns={Object.keys(rest.columns)}
        systemFields={false}
        entityName={rest.entityName}
        initialValues={initialValues}
        baseColumnsConfig={baseColumnsConfig}
      >
        <Component {...rest} />
      </TableProvider>
    );
  };
export const SimpleTable = connectProvider(SimpleTableBase);
