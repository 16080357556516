import resource from './resource';
import historylog from './historylog';
import businessunit from './businessunit';
import person from './person';
import application from './application';
import facilitator from './facilitator';
import session from './session';
import seminar from './seminar';
import participant from './participant';

export const systemBasedFields = ['ownerid', 'createdon', 'createdby', 'modifiedon'] as const;
export type SystemFields = (typeof systemBasedFields)[number];

export default {
  person: {
    name: 'bahai_person',
    ...person,
  },
  session: {
    name: 'bahai_session',
    ...session,
  },
  seminar: {
    name: 'bahai_seminar',
    ...seminar,
  },
  application: {
    name: 'bahai_application',
    ...application,
  },
  resource: {
    name: 'systemuser',
    ...resource,
  },
  facilitator: {
    name: 'bahai_facilitator',
    ...facilitator,
  },
  businessunit: {
    name: 'businessunit',
    ...businessunit,
  },
  userroles: {
    name: 'systemuserroles',
    columns: [],
    fields: [],
  },
  role: {
    name: 'role',
    columns: [],
    fields: [],
  },
  teammembership: {
    name: 'teammembership',
    columns: [],
    fields: [],
  },
  team: {
    name: 'team',
    columns: [],
    fields: [],
  },
  locality: {
    name: 'businessunit',
    fields: [],
    columns: ['bahai_name', 'bahai_localitytype'],
  },
  cluster: {
    name: 'businessunit',
    fields: [],
    columns: ['bahai_name', 'bahai_stageofgrowth'],
  },
  historylog: {
    name: 'bahai_historylog',
    ...historylog,
  },
  participant: {
    name: 'bahai_participant',
    ...participant,
  },
  state: {
    name: 'bahai_state',
    fields: [],
    columns: ['bahai_stateregionid'],
  },
} as const;
