import { useMetaData, useTimeZone } from 'lib/hooks';
import { useApi } from 'domain/api';
import { ServerErrorType, useServerError } from 'providers/ErrorProvider';
import { useCallback, useMemo } from 'react';
import { FieldType, isDateTime, TEntityName } from 'lib/types';
import { EntityLink, TLinkEntity } from 'components/ListPage';
import * as emailMetadata from 'config/EntityMetadata/email';
import { toDisplayDate, toISO, toISODate, toIsoDate } from 'lib/adapter';
import mainConfig, { systemBasedFields } from 'config';
import { devLog } from 'lib/helpers';

type TPartyLists = {
  [key: string]: any;
};

export const useRecord = (entityName: TEntityName, dontHideFields = false) => {
  const fieldsList = useMemo(() => mainConfig[entityName].fields as readonly string[], [entityName]);
  const { timeZone } = useTimeZone();

  const {
    config,
    entityConfig: { url, logicalName, PrimaryIdAttribute, PrimaryNameAttribute, schemaName, fields, isActivity },
    joinParams,
    getFieldDefinition,
    getJoinUrl,
    getTargetLogicalNames,
    hiddenFields,
  } = useMetaData(entityName);

  const formIgnoredFields = useMemo(
    () =>
      (systemBasedFields as any as string[])
        .filter((v) => !fieldsList.includes(v))
        .concat(...(dontHideFields ? [] : hiddenFields)),
    [dontHideFields, fieldsList, hiddenFields]
  );

  const addServerError = useServerError();

  const filteredIgnoreFields = useMemo(
    () =>
      formIgnoredFields.reduce(
        (acc, name) =>
          !name.includes('.') || name.startsWith(`${entityName}.`)
            ? acc.concat(name.replace(`${entityName}.`, ''))
            : acc,
        [] as string[]
      ),
    [entityName, formIgnoredFields]
  );

  const { request } = useApi();

  const getQuery = useCallback(
    (id: string, links?: TLinkEntity) => ({
      fetch: {
        _attributes: {
          version: '1.0',
          'output-format': 'xml-platform',
          mapping: 'logical',
          returntotalrecordcount: true,
          'no-lock': true,
        },
        entity: {
          _attributes: {
            name: logicalName,
          },
          'link-entity': [
            ...(links
              ? Object.entries(links).map(([name, fields]) => {
                  const { logicalName } = config[name as TEntityName];
                  if (!Array.isArray(fields)) {
                    const { from, to, fields: fieldList } = fields as EntityLink;
                    return {
                      _attributes: {
                        name: logicalName,
                        from,
                        to,
                        'link-type': 'outer',
                        alias: name,
                      },
                      attribute: fieldList.map((name) => ({
                        _attributes: { name },
                      })),
                    };
                  }
                  return {
                    _attributes: {
                      name: logicalName,
                      from: joinParams[logicalName].PrimaryIdAttribute,
                      to: joinParams[logicalName].PrimaryIdAttribute,
                      'link-type': 'outer',
                      alias: name,
                    },
                    attribute: (fields as string[]).map((name) => ({
                      _attributes: { name },
                    })),
                  };
                })
              : []),
          ],
          attribute: Object.keys(fields)
            .filter((v) => !v.includes('.') && v !== PrimaryNameAttribute)
            .concat(PrimaryNameAttribute)
            .map((name) => ({ _attributes: { name } })),
          filter: {
            _attributes: { type: 'and' },
            condition: {
              _attributes: {
                attribute: PrimaryIdAttribute,
                operator: 'eq',
                value: id,
              },
            },
          },
        },
      },
    }),
    [PrimaryIdAttribute, PrimaryNameAttribute, config, fields, joinParams, logicalName]
  );

  const getActivityParties = useCallback(
    (data: Record<string, any>) => {
      if (!data) return;
      const partyListsData = data[`${logicalName}_activity_parties`];

      if (Array.isArray(partyListsData)) {
        const partyLists: {
          [key: string]: any;
        } = {};

        for (const value in emailMetadata.ParticipationTypeMask) {
          if (isNaN(Number(value))) {
            const key = emailMetadata.ParticipationTypeMask[value];
            partyLists[value] = partyListsData
              .filter((item) => item.participationtypemask == key)
              .map(
                (item) =>
                  `${item['_partyid_value@Microsoft.Dynamics.CRM.lookuplogicalname']}<|>${item._partyid_value}<|>${item['_partyid_value@OData.Community.Display.V1.FormattedValue']}<|>${item.addressused}`
              )
              .flat();
          }
        }
        return partyLists;
      }
      return {};
    },
    [logicalName]
  );

  const getPolymorphicFields = useCallback((data: Record<string, any>) => {
    if (!data) return;

    const polymorphicFields = Object.keys(data).filter((k: string) => k.endsWith('objectid_value'));
    if (polymorphicFields.length > 0) {
      return polymorphicFields.reduce(
        (res, name) => {
          return {
            ...res,
            [name]: `${data[`${name}@Microsoft.Dynamics.CRM.lookuplogicalname`]}<|>${data[name]}`,
          };
        },
        {} as Record<string, any>
      );
    }

    return {};
  }, []);

  const formatDateTimeFields = useCallback(
    (data: Record<string, any>) =>
      Object.fromEntries(
        Object.entries(fields)
          .filter(([name, { type }]) => !!data[name] && type === FieldType.DateTime)
          .map(([name, { format }]) => [name, (isDateTime(format) ? toISO : toISODate)(data[name])])
      ),
    [fields]
  );

  const parseMultiReferencedFields = useCallback(
    (data: Record<string, any>): Record<string, any> => ({
      ...data,
      //...formatDateTimeFields(data),
      ...getActivityParties(data),
      ...getPolymorphicFields(data),
    }),
    [getActivityParties, getPolymorphicFields]
  );

  const getData = useCallback(
    async (id: string, links?: TLinkEntity) => {
      try {
        const {
          data: {
            value: [data],
          },
        } = await request<{ value: Record<string, any>[] }>({ url, query: getQuery(id, links) });
        if (!data) {
          addServerError(ServerErrorType.Errr404);
          return {} as Record<string, any>;
        }
        return parseMultiReferencedFields(data);
      } catch (e: any) {
        addServerError(e);
        return {} as Record<string, any>;
      }
    },
    [addServerError, getQuery, parseMultiReferencedFields, request, url]
  );

  const getFieldValue = useCallback(
    (name: string, data: Record<string, any>) => {
      if (!data) return;
      const { extraType, type, format } = getFieldDefinition(name);
      switch (type) {
        case FieldType.DateTime:
          return toDisplayDate(data[name], isDateTime(format));
        case FieldType.Owner:
          if (!data[`_${name}_value`]) return;
          return data[`_${name}_value@Microsoft.Dynamics.CRM.lookuplogicalname`] + '<|>' + data[`_${name}_value`];
        case FieldType.Lookup:
          return data[`_${name}_value`];
        case FieldType.Virtual:
          if (extraType === '#Microsoft.Dynamics.CRM.MultiSelectPicklistAttributeMetadata') {
            return data[name] ? data[name].split(',').filter((v: string) => !!v) : [];
          }
          return data[name];
        default:
          return data[name];
      }
    },
    [getFieldDefinition]
  );

  const getFormValues = useCallback(
    (data: Record<string, any>, ignore: string[] = [], additionalFields: string[] = []): Record<string, any> =>
      Object.fromEntries(
        [...fieldsList, ...additionalFields]
          .filter((v) => !([...formIgnoredFields, ...ignore] as string[]).includes(v))
          .map((name) => [name, getFieldValue(name, data)])
      ),
    [fieldsList, formIgnoredFields, getFieldValue]
  );

  const formatFormValue = useCallback(
    (name: string, value: any): [string, any] => {
      if (!fields[name]) {
        console.error('No config for - ' + name);
      }
      let relatedEntity = '';
      let parsedValue = '';
      let targets = [];
      let isMultiReferenceLookup = false;

      const { type, format } = getFieldDefinition(name);

      switch (type) {
        case FieldType.DateTime:
          return [name, toIsoDate(value as string, format !== 'DateOnly') || null];
        case FieldType.Owner:
        case FieldType.Lookup:
          targets = getTargetLogicalNames(name);
          isMultiReferenceLookup = targets && targets.length > 1;
          if (isMultiReferenceLookup && !value) {
            return [`${name}_${targets[0]}_${logicalName}@odata.bind`, null];
          } else if (isMultiReferenceLookup) {
            [relatedEntity, parsedValue] = value.toString().split('<|>');
            return [
              `${name}${fields[name]?.type === FieldType.Owner ? '' : '_' + relatedEntity}@odata.bind`,
              `/${joinParams[relatedEntity].LogicalCollectionName}(${parsedValue})`,
            ];
          } else {
            if (!value) return [name + `${isActivity ? '_' + schemaName : ''}`, null];
            return [
              name + `${isActivity ? '_' + (schemaName || logicalName) : ''}` + '@odata.bind',
              `/${getJoinUrl(name)}(${value})`,
            ];
          }
        case FieldType.Picklist:
        case FieldType.Virtual:
          // TODO: check types here;
          try {
            if (fields[name].extraType === '#Microsoft.Dynamics.CRM.PicklistAttributeMetadata') {
              return [name, Array.isArray(value) ? (value.length ? value : null) : value || null];
            } else {
              return [name, value ? value.join(',') || null : null];
            }
          } catch (e) {
            return [name, value];
          }
        default:
          return [name, value];
      }
    },
    [fields, getFieldDefinition, getTargetLogicalNames, logicalName, joinParams, isActivity, schemaName, getJoinUrl]
  );

  const getPartyMaskCodeByName = (name: string) => {
    const match = Object.entries(emailMetadata.ParticipationTypeMask).find(([_, value]) => value === name);
    if (!match) throw new Error(`Can't define participationtypemask!`);
    return match[0];
  };

  const formatFormPartyListValue = useCallback(
    (name: string, value: any) => {
      try {
        const parsePartyListValue = (value: string) => {
          const [logicalName, parsedValue, _, email] = value.split('<|>');

          let field = [`addressused`, email];
          if (logicalName !== 'undefined') {
            const logicalCollectionName = joinParams[logicalName].LogicalCollectionName;
            field = [`partyid_${logicalName}@odata.bind`, `/${logicalCollectionName}(${parsedValue})`];
          }
          const partyMaskCode = getPartyMaskCodeByName(name);
          return Object.fromEntries([field, ['participationtypemask', partyMaskCode]]);
        };

        if (Array.isArray(value)) {
          return (value as []).map((item: string) => parsePartyListValue(item)).flat();
        }
        return parsePartyListValue(value);
      } catch (e) {
        devLog(name, e);
        return { [name]: value };
      }
    },
    [joinParams]
  );

  const replaceEntityValues = useCallback(
    <T extends Record<string, any>>(
      data: Partial<T>,
      initialValues: Record<string, any>,
      skipIgnoringFields = false,
      ignore: string[] = []
    ) => {
      const ignoredFields = [...filteredIgnoreFields, ...ignore];
      devLog(Object.entries(data).filter(([key]) => !fields[key]));
      const pl = Object.entries(data)
        .filter(
          ([key, value]) =>
            (skipIgnoringFields || !ignoredFields.includes(key)) &&
            value !== undefined &&
            fields[key].type === FieldType.PartyList
        )
        .map(([key, value]) => formatFormPartyListValue(key, value));

      const partyLists: TPartyLists = {};
      if (typeof pl !== 'undefined' && pl.length > 0) {
        partyLists[`${logicalName}_activity_parties`] = pl.flat();
      }

      const emptyFields = Object.fromEntries(
        Object.keys(initialValues)
          .filter(
            (key) =>
              ![FieldType.Lookup, FieldType.Owner, FieldType.PartyList].includes(fields[key].type) ||
              data[key] === undefined
          )
          .map((key) => formatFormValue(key, null))
      );

      const formattedData = Object.fromEntries<Partial<T>>(
        Object.entries(data)
          .filter(
            ([key, value]) =>
              (skipIgnoringFields || !ignoredFields.includes(key)) &&
              value !== undefined &&
              fields[key].type !== FieldType.PartyList
          )
          .map(([key, value]) => formatFormValue(key, typeof value === 'string' ? value.trim() : value))
      );

      return {
        ...emptyFields,
        ...formattedData,
        ...formatDateTimeFields(formattedData),
        ...partyLists,
      };
    },
    [filteredIgnoreFields, formatDateTimeFields, fields, formatFormPartyListValue, logicalName, formatFormValue]
  );

  const getInitialValues = useCallback(
    async (id?: string, ignore = [] as string[]) => {
      if (!id) return {} as Record<string, any>;
      const recordData = await getData(id);
      return getFormValues(recordData, ignore);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getData, getFormValues, timeZone]
  );

  const save = useCallback(
    async (formData: Record<string, any>, id?: string, ignore = [] as string[]) => {
      const data = replaceEntityValues(formData, await getInitialValues(id, ignore), false, ignore);
      return request<Record<string, any>>({
        url: id ? `${url}(${id})` : url,
        data,
        method: id ? 'patch' : 'post',
        ...(id ? { eTag: '*' } : {}),
      });
    },
    [getInitialValues, replaceEntityValues, request, url]
  );

  const patch = useCallback(
    async (data: Record<string, any>, id: string) => {
      return request<Record<string, any>>({
        url: `${url}(${id})`,
        data,
        method: 'patch',
        eTag: '*',
      });
    },
    [request, url]
  );

  return { getQuery, getData, save, patch, getFormValues, replaceEntityValues, getInitialValues };
};
