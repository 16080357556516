import SinglePage from 'components/SinglePage';
import {
  config,
  getDetailsConfig,
  getFormConfig,
  isActive,
  isNotEditable,
  isNotRemovable,
  validation,
} from 'schemas/resource';
import { Improver } from 'pages/ResourceList';

export const Resource = () => {
  return (
    <SinglePage
      entityName="resource"
      displayEdit={true}
      displayRemove={false}
      FormImprover={Improver}
      {...{
        config,
        getFormConfig,
        getDetailsConfig,
        isActive,
        isNotEditable,
        validation,
        isNotRemovable,
      }}
    />
  );
};
