import { FileRejection, useDropzone } from 'react-dropzone';
import { useCallback, useState, useRef, useEffect, useMemo, ReactNode } from 'react';
import { ReactComponent as DeleteIcon } from 'components/Image/icons/edit.svg';
import { ReactComponent as ImageIcon } from 'components/Image/icons/image.svg';
import classes from 'components/Image/image.module.scss';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { IconButton } from 'components/IconButton';
import { Loader } from 'components/Loader';
import { useNotifications } from 'providers/NotificationsProvider';
import UploadImagesMenu from 'components/UploadImagesMenu';

const MB = 1024 * 1024;
const maxFileSize = 10;
const formats = ['jpeg', 'gif', 'bmp', 'png'];

export const Image = ({
  image,
  onChange,
  onRemove,
  loading = false,
  isNotEditable,
  lettersPlaceholder,
}: {
  image?: string;
  loading: boolean;
  onChange: (file: string) => void;
  onRemove: () => void;
  isNotEditable?: ReactNode;
  lettersPlaceholder: string | undefined;
}) => {
  const { t } = useTranslation();
  const { addError } = useNotifications();

  const onDrop = useCallback(
    (acceptedFiles: File[], rejected: FileRejection[]) => {
      const reader = new FileReader();
      if (acceptedFiles[0]) {
        reader.readAsDataURL(acceptedFiles[0]);
        reader.onloadend = function () {
          onChange(reader.result as string);
        };
      }
      if (rejected[0]?.errors.length > 0) {
        const [error] = rejected[0].errors;
        switch (error.code) {
          case 'file-invalid-type':
            addError({
              title: t('Image Upload Error'),
              content: t('File type must be one of ') + formats.join(', ') + '.',
            });
            break;
          case 'file-too-large':
            addError({
              title: t('Image Upload Error'),
              content: t('File size is more than {{maxFileSize}} MB', { maxFileSize }),
            });
            break;
          default:
            addError({ title: t('Image Upload Error'), content: error.message });
        }
      }
    },
    [addError, onChange, t]
  );
  const [showUploadMenu, setShowUploadMenu] = useState(false);
  const uploadMenuRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: Event) => {
    if (uploadMenuRef.current && !uploadMenuRef.current.contains(event.target as Node)) {
      setShowUploadMenu(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const onEditClick = useCallback(() => {
    setShowUploadMenu(!showUploadMenu);
  }, [showUploadMenu]);

  const { getRootProps, open, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: maxFileSize * MB,
    accept: Object.fromEntries(formats.map((v) => ['image/' + v, [`.${v}`]])),
  });

  const placeholderBgColor = useMemo(() => {
    const letterColors = [
      '#FFB3BA',
      '#FFFFBA',
      '#BAFFC9',
      '#E6A9EC',
      '#FFDAC1',
      '#D1E7DD',
      '#F8BBD0',
      '#DCEDC8',
      '#FFE0B2',
      '#C8E6C9',
      '#E1BEE7',
    ];

    return lettersPlaceholder && letterColors[lettersPlaceholder.charCodeAt(0) % 10];
  }, [lettersPlaceholder]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.flex}>
        <div
          className={cx(classes.root, isNotEditable ? null : classes.rootHover)}
          {...getRootProps()}
          onClick={() => onEditClick()}
        >
          {image ? (
            <div className={classes.placeholder}>
              {loading ? <Loader withoutLoadingText={true} /> : <img alt="image" src={image} />}
            </div>
          ) : (
            <>
              <div
                className={classes.placeholder}
                style={{ backgroundColor: isNotEditable ? placeholderBgColor : `#c6c6c6` }}
              >
                {loading ? (
                  <Loader withoutLoadingText={true} />
                ) : lettersPlaceholder ? (
                  <>{lettersPlaceholder}</>
                ) : (
                  <ImageIcon />
                )}
              </div>
            </>
          )}
          <>
            <input {...getInputProps()} />
            {<IconButton className={classes.btn} iconOnly onClick={onEditClick} Icon={DeleteIcon} />}
          </>
        </div>
      </div>
      <>
        {showUploadMenu && (
          <div ref={uploadMenuRef}>
            <UploadImagesMenu
              uploadImage={() => {
                open();
                setShowUploadMenu(false);
              }}
              deleteImage={() => {
                onRemove();
                setShowUploadMenu(false);
              }}
              image={!!image}
              isNotEditable={isNotEditable}
            />
          </div>
        )}
      </>
    </div>
  );
};
