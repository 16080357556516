import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { PortalFilter, TEntityName } from 'lib';
import { Sort } from 'components/Table/hooks';
import { useHistory } from 'react-router-dom';
import { ColumnConfig, View } from 'components/ListPage/hook';
import { TListPage } from 'components/ListPage';
import config, { systemBasedFields } from 'config';
import { useMetaData } from 'lib/hooks';
import { UserSettingsContext } from 'providers/UserSettingsProvider';
import { useViews } from 'providers/TableProvider/hooks';
import { TLinkEntity, EntityLink } from 'components/ListPage';
import { devLog } from 'lib/helpers';

export type QueryParams = {
  page: number;
  sorting: Sort[];
  filters: PortalFilter[];
  search?: string;
  links?: TLinkEntity;
};

export type ListProps = {
  queryParams: QueryParams;
  columnsConfig: ColumnConfig[];
  currentView: string;
  updateQueryParams: (props: Partial<QueryParams>) => void;
  setColumnsConfig: Dispatch<SetStateAction<ColumnConfig[]>>;
  setCurrentView: (id: string) => void;
  columns: readonly string[];
  views: Record<string, View>;
  setViews?: Dispatch<SetStateAction<Record<string, View>>>;
  logicalName: string;
};

type ViewProps = {
  hasChanges: boolean;
  isCustomView: boolean;
};

export const TableContext = createContext({} as ListProps & ViewProps);

const getSavedParams = (key: string, clear = false) => {
  const savedParams = localStorage.getItem(key);
  if (savedParams && !clear) {
    devLog('Has saved View params');
    try {
      const {
        columnsConfig,
        currentView = '',
        ...savedQueryParams
      } = JSON.parse(savedParams) as QueryParams & {
        columnsConfig: ColumnConfig[];
        currentView: string;
      };
      return { savedQueryParams, savedCurrentView: currentView, savedColumnsConfig: columnsConfig };
    } catch (e) {
      devLog('Error Parsing Saved View Params');
    }
  }
  devLog('Clear View Params');
  localStorage.removeItem(key);
  return {};
};

export function deepEqual(first: any, second: any, ignoredKeys: string[] = []): boolean {
  if (first === second) return true;
  if (typeof first !== typeof second) return false;
  if (Array.isArray(first) && Array.isArray(second)) {
    if (first.length !== second.length) return false;
    return first.every((value, index) => deepEqual(value, second[index]));
  }
  if (typeof first === 'object' && typeof second === 'object' && first !== null && second !== null) {
    if (Object.keys(first).length !== Object.keys(second).length) return false;
    return Object.keys(first)
      .filter((key) => !ignoredKeys.includes(key))
      .every((key) => deepEqual(first[key], second[key]));
  }
  return first === second;
}

export const unifyPathName = (pathname: string) =>
  pathname
    .split('/')
    .filter((v) => !!v)
    .filter((_, index) => index !== 1)
    .join('/');

export const MIN_CELL_WIDTH = 160;

export const TableProvider: FC<{
  initialValues?: Partial<QueryParams>;
  entityName: TEntityName;
  dialog?: string;
  columns: readonly string[];
  systemView?: string;
  systemFields?: boolean;
  views?: Record<string, View>;
  baseColumnsConfig?: ColumnConfig[];
  children?: ReactNode;
  setViews?: Dispatch<SetStateAction<Record<string, View>>>;
  displayViews?: boolean;
}> = ({
  initialValues = {},
  entityName,
  views,
  columns: baseColumnsList,
  dialog = '',
  setViews,
  systemView,
  systemFields = true,
  children,
  baseColumnsConfig,
  displayViews = true,
}) => {
  const { logicalName, getFieldDefinition, hiddenFields } = useMetaData(entityName);

  const {
    action,
    location: { pathname },
  } = useHistory();
  const key = ['list-props', unifyPathName(pathname), dialog].filter((v) => !!v).join('/');

  const savedParams = useRef(getSavedParams(key, action !== 'POP'));

  const { savedQueryParams, savedCurrentView, savedColumnsConfig } = savedParams.current;

  const { defaultViews } = useContext(UserSettingsContext);
  const pinnedView = useMemo(() => defaultViews[entityName], [defaultViews, entityName]);

  const columns = useMemo(
    () =>
      [...(systemFields ? (systemBasedFields as unknown as string[]) : []), ...baseColumnsList].filter(
        (v, index, arr) => arr.indexOf(v) === index && getFieldDefinition(v) && !hiddenFields.includes(v)
      ),
    [baseColumnsList, getFieldDefinition, hiddenFields, systemFields]
  );

  const systemViewId = useMemo(
    () => Object.values(views || {}).find((v) => v.isSystem && v.name === systemView)?.id,
    [systemView, views]
  );

  const defaultViewId = useMemo(() => Object.values(views || {}).find((v) => v.isSystem)?.id, [views]);

  const getCurrentView = useCallback(() => {
    if (!views) return '';
    switch (true) {
      case !!savedCurrentView && !!views[savedCurrentView]:
        return savedCurrentView;
      case !!pinnedView && !!views[pinnedView] && displayViews:
        return pinnedView;
      case !!systemViewId:
        return systemViewId;
      case !!defaultViewId:
        return defaultViewId;
      default:
        return '';
    }
  }, [defaultViewId, displayViews, pinnedView, savedCurrentView, systemViewId, views]);

  const [currentView, setCurrentView] = useState(getCurrentView);

  const checkFieldExists = useCallback(
    (name: string) => {
      const [shortName, realEntityName] = name.split('.').reverse();
      const exist =
        [
          ...(config[(realEntityName || entityName) as TEntityName].columns as readonly string[]),
          ...systemBasedFields,
        ].includes(shortName) && !hiddenFields.includes(name);
      if (!exist) devLog('New field ', name);
      return exist;
    },
    [entityName, hiddenFields]
  );

  const clearOutdatedQueryParams = useCallback(
    (props: QueryParams) => ({
      search: '',
      ...props,
      sorting: (props.sorting || []).filter(({ name }) => checkFieldExists(name)),
      filters: (props.filters || []).filter(({ attribute: name }) => checkFieldExists(name)),
      links: Object.fromEntries(
        Object.entries(props.links || {})
          .map(([linkName, link]) => [
            linkName,
            {
              ...link,
              condition: ((link as EntityLink)?.condition || []).filter(({ attribute: name }) =>
                checkFieldExists(`${linkName}.${name}`)
              ),
            },
          ])
          .filter(([, link]) => ((link as EntityLink)?.condition?.length ?? 0) > 0)
      ),
    }),
    [checkFieldExists]
  );

  const defaultColumnsConfig = useMemo(
    () =>
      baseColumnsConfig ||
      columns.map((name) => ({
        name,
        visible: false,
        pinned: false,
        width: MIN_CELL_WIDTH,
      })),
    [baseColumnsConfig, columns]
  );

  const clearOutdatedColumns = useCallback(
    (columnsConfig: ColumnConfig[]) =>
      (columnsConfig || [])
        .concat(...defaultColumnsConfig)
        .filter(({ name }, index, arr) => checkFieldExists(name) && arr.findIndex((v) => v.name === name) === index),
    [checkFieldExists, defaultColumnsConfig]
  );

  const newViewId = useRef('');

  const updateCurrentView = useCallback(
    (id: string) => {
      devLog('Set View ', id);
      if (views && views[id]) {
        setCurrentView(id);
        const { filters, links, search, sorting, columnsConfig } = views[id];
        setQueryParams(clearOutdatedQueryParams({ filters, links: links, search, sorting, page: 1 }));
        setColumnsConfig(clearOutdatedColumns(columnsConfig));
        newViewId.current = '';
      } else {
        newViewId.current = id;
      }
    },
    [clearOutdatedColumns, clearOutdatedQueryParams, views]
  );

  useEffect(() => {
    if (views && !views[currentView]) {
      updateCurrentView(getCurrentView());
    }
  }, [currentView, getCurrentView, updateCurrentView, views]);

  useEffect(() => {
    if (newViewId.current && views?.[newViewId.current]) {
      updateCurrentView(newViewId.current);
      newViewId.current = '';
    }
  }, [updateCurrentView, views]);

  const baseViewParams = useMemo(() => {
    if (!currentView || !views?.[currentView]) return;
    const { filters, links, search, sorting, columnsConfig } = views[currentView];
    return {
      ...clearOutdatedQueryParams({ filters, links, search, sorting, page: 1 }),
      columnsConfig: clearOutdatedColumns(columnsConfig),
    };
  }, [clearOutdatedColumns, clearOutdatedQueryParams, currentView, views]);

  const [columnsConfig, setColumnsConfig] = useState<ColumnConfig[]>(() => {
    if (savedColumnsConfig) return clearOutdatedColumns(savedColumnsConfig);
    if (baseViewParams?.columnsConfig) return clearOutdatedColumns(baseViewParams.columnsConfig);
    return defaultColumnsConfig.map((v) => ({ ...v, visible: true }));
  });

  const [queryParams, setQueryParams] = useState<QueryParams>(() => {
    if (savedQueryParams) return clearOutdatedQueryParams(savedQueryParams);
    if (baseViewParams) {
      const { filters, links, search, sorting } = baseViewParams;
      return { filters, links, search, sorting, page: 1 };
    }
    return {
      page: 1,
      sorting: [],
      search: '',
      filters: [],
      links: {},
      ...initialValues,
    };
  });

  const updateQueryParams = useCallback(
    (params: Partial<QueryParams>) => setQueryParams((v) => ({ ...v, ...params })),
    []
  );

  const hasChanges = useMemo(() => {
    return baseViewParams ? !deepEqual({ columnsConfig, ...queryParams }, baseViewParams, ['page']) : false;
  }, [baseViewParams, columnsConfig, queryParams]);

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify({ currentView, ...queryParams, columnsConfig }));
  }, [columnsConfig, currentView, hasChanges, key, queryParams]);

  const isCustomView = useMemo(() => !views?.[currentView]?.isSystem, [currentView, views]);

  return (
    <TableContext.Provider
      value={{
        setCurrentView: updateCurrentView,
        setColumnsConfig,
        updateQueryParams,
        queryParams,
        columnsConfig,
        hasChanges,
        currentView,
        columns,
        views: views || {},
        setViews,
        logicalName,
        isCustomView,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};

const ViewLayer: FC<{
  children: ReactNode;
  entityName: TEntityName;
  systemFields?: boolean;
  columns: readonly string[];
  systemView?: string;
  hasViews?: boolean;
  dialog?: string;
  displayViews?: boolean;
}> = ({
  children,
  entityName,
  displayViews = true,
  columns: baseColumnsList,
  systemFields = true,
  dialog,
  systemView,
  hasViews = true,
}) => {
  const { getFieldDefinition, hiddenFields } = useMetaData(entityName);

  const columns = useMemo(
    () =>
      [...(systemFields ? (systemBasedFields as unknown as string[]) : []), ...baseColumnsList].filter(
        (v, index, arr) => arr.indexOf(v) === index && getFieldDefinition(v) && !hiddenFields.includes(v)
      ),
    [baseColumnsList, getFieldDefinition, hiddenFields, systemFields]
  );

  const { getViews } = useViews(entityName, columns, systemView);

  const [views, setViews] = useState<Record<string, View>>({});

  useEffect(() => {
    if (hasViews) getViews().then(setViews).catch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!Object.keys(views).length && hasViews) return null;

  return (
    <TableProvider
      views={views}
      setViews={setViews}
      entityName={entityName}
      dialog={dialog}
      columns={columns}
      systemFields={systemFields}
      displayViews={displayViews}
    >
      {children}
    </TableProvider>
  );
};

export const connectListPropsProvider =
  (Component: (props: TListPage) => JSX.Element) =>
  ({ columns: baseColumns, ...props }: TListPage & { dialog?: TEntityName; columns: readonly string[] }) => {
    const { hiddenFields } = useMetaData(props.entityName);
    const columns = useMemo(
      () => baseColumns.filter((name) => props.config?.[name]?.hiddenForTable !== true && !hiddenFields.includes(name)),
      [baseColumns, hiddenFields, props.config]
    );
    return (
      <ViewLayer columns={columns} {...props}>
        <Component {...props} />
      </ViewLayer>
    );
  };
