import { FC, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ThType } from 'components/Table/types';
import { ResizableBox } from 'react-resizable';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as ChevronDownIcon } from './icons/chevronDown.svg';
import { ReactComponent as FilterIcon } from './icons/filter.svg';
import { ReactComponent as ClearFilterIcon } from './icons/clearFilter.svg';
import { ReactComponent as PinIcon } from './icons/pin.svg';
import { ReactComponent as ClearSorting } from './icons/clearSorting.svg';
import cx from 'classnames';
import { Filter } from 'components/Table/Filter';
import { Trans } from 'react-i18next';
import { DropdownWrapper } from 'components/DropdownWrapper';

const Th = ({
  name,
  classes,
  width: cellWidth,
  onCellResize,
  isPinned,
  pinColumn,
  sortDirection,
  sortIndex,
  sortByName,
  clearByName,
  config,
  filterConfig,
  tableName,
  showControls = true,
  isMultiSorting = false,
  removeFilter,
  clearOtherFilters,
}: ThType) => {
  const ref = useRef<HTMLDivElement>(null);
  const [stateWidth, setWidth] = useState(cellWidth);
  const width = useMemo(() => stateWidth + 'px', [stateWidth]);

  const [filter, setFilter] = useState<null | { left: number; top: number }>(null);
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const toggleMenu = useCallback(() => setIsMenuVisible((v) => !v), []);

  const hideFilter = useCallback(() => setFilter(null), [setFilter]);

  const showFilter = useCallback(() => {
    const { left, bottom: top } = ref?.current?.getBoundingClientRect() || {
      left: 0,
      bottom: 0,
    };
    if (left && top) {
      setFilter({ left, top });
    }
  }, [setFilter]);

  const onPin = useCallback(() => pinColumn && pinColumn(name), [pinColumn, name]);

  const widthRef = useRef(cellWidth);
  const onResize = useCallback((event: any, { size }: { size: { width: number } }) => {
    setWidth(size.width);
    widthRef.current = size.width;
  }, []);

  const [displayLine, setDisplayLine] = useState(false);

  useEffect(() => {
    const tableHeaderElement = document.querySelector<HTMLDivElement>(`.${classes.thead}`);
    const handleTouchMove = (event: TouchEvent) => {
      if (displayLine) {
        event.preventDefault();
      }
    };

    tableHeaderElement?.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      tableHeaderElement?.removeEventListener('touchmove', handleTouchMove);
    };
  }, [displayLine, classes]);

  const resizeStart = useCallback(() => setDisplayLine(true), []);

  const resizeStop = useCallback(() => {
    onCellResize && onCellResize(name, widthRef.current);
    setDisplayLine(false);
  }, [name, onCellResize]);

  useEffect(() => {
    setWidth(cellWidth);
  }, [cellWidth]);

  const Wrapper: FC<{ width: number; children: ReactNode }> = useMemo(() => {
    return ({ children, width }) =>
      onCellResize ? (
        <ResizableBox
          className={cx(classes.resizeWrapper)}
          minConstraints={[100, 40]}
          width={width}
          height={40}
          axis="x"
          onResize={onResize}
          onResizeStart={resizeStart}
          onResizeStop={resizeStop}
        >
          <>{children}</>
        </ResizableBox>
      ) : (
        <div className={cx(classes.resizeWrapper, classes.noResize)}>{children}</div>
      );
  }, [classes.noResize, classes.resizeWrapper, onCellResize, onResize, resizeStart, resizeStop]);

  return (
    <Wrapper width={stateWidth}>
      <div
        id={`column_${config.name}`}
        ref={ref}
        className={classes.th}
        style={{ width }}
        onClick={!filter && showControls ? toggleMenu : undefined}
      >
        <div className={classes.text}>{config.label}</div>
        <>
          {isMenuVisible && !filter && ref.current && (
            <DropdownWrapper parent={ref.current} onClose={() => setIsMenuVisible(false)}>
              <div className={classes.menuWrapper}>
                {config.sortable !== false && (
                  <>
                    <button
                      onClick={() => sortByName('asc')}
                      type="button"
                      className={cx(classes.iconBtn, {
                        [classes.btnActive]: sortDirection === 'asc',
                      })}
                    >
                      <ArrowIcon className={cx(classes.icon, classes.flip)} />
                      <div style={{ color: `var(--textSecondary)` }}>
                        <Trans>Sort A to Z</Trans>
                      </div>
                    </button>
                    <button
                      onClick={() => sortByName('desc')}
                      type="button"
                      className={cx(classes.iconBtn, {
                        [classes.btnActive]: sortDirection === 'desc',
                      })}
                    >
                      <ArrowIcon className={cx(classes.icon)} />
                      <div style={{ color: `var(--textSecondary)` }}>
                        <Trans>Sort Z to A</Trans>
                      </div>
                    </button>
                    {!!sortDirection && (
                      <>
                        <button onClick={() => clearByName()} type="button" className={cx(classes.iconBtn)}>
                          <ClearSorting className={cx(classes.icon)} style={{ fill: `var(--themePrimary)` }} />
                          <div>
                            <Trans>Clear Sorting</Trans>
                          </div>
                        </button>
                        {isMultiSorting && (
                          <button onClick={() => clearByName(true)} type="button" className={cx(classes.iconBtn)}>
                            <ClearSorting className={cx(classes.icon)} style={{ fill: `var(--themePrimary)` }} />
                            <div>
                              <Trans>Clear Other Sortings</Trans>
                            </div>
                          </button>
                        )}
                      </>
                    )}
                    <div className={classes.menuDivider} />
                  </>
                )}
                {!config.hideFilters && (
                  <button
                    id={`${config.name}_filterBy`}
                    onClick={showFilter}
                    type="button"
                    className={cx(classes.iconBtn, {
                      [classes.btnActive]: !!removeFilter || filter,
                    })}
                  >
                    <FilterIcon className={classes.icon} />
                    <div>
                      <Trans>Filter By</Trans>
                    </div>
                  </button>
                )}
                {!!removeFilter && (
                  <>
                    <button
                      id={`${config.name}_clearFilter`}
                      onClick={removeFilter}
                      type="button"
                      className={classes.iconBtn}
                    >
                      <ClearFilterIcon className={classes.icon} />
                      <div>
                        <Trans>Clear Filter</Trans>
                      </div>
                    </button>
                    {!!clearOtherFilters && !config.hideFilters && (
                      <button
                        id={`${config.name}_clearOtherFilters`}
                        onClick={clearOtherFilters}
                        type="button"
                        className={classes.iconBtn}
                      >
                        <ClearFilterIcon className={classes.icon} />
                        <div>
                          <Trans>Clear Other Filters</Trans>
                        </div>
                      </button>
                    )}
                  </>
                )}
                {!!pinColumn && (
                  <>
                    <div className={classes.menuDivider} />
                    <button
                      onClick={onPin}
                      type="button"
                      className={cx(classes.iconBtn, { [classes.btnActive]: isPinned })}
                    >
                      <PinIcon className={classes.icon} />
                      <div>
                        <Trans>Pin Column</Trans>
                      </div>
                    </button>
                  </>
                )}
              </div>
            </DropdownWrapper>
          )}
          <div className={classes.iconBox}>
            {isPinned && <PinIcon className={classes.icon} />}
            {(!!removeFilter || filter) && <FilterIcon className={classes.icon} />}
            {!!sortDirection && (
              <>
                <ArrowIcon
                  className={cx(classes.icon, {
                    [classes.flip]: sortDirection === 'asc',
                  })}
                />
                {sortIndex !== undefined && <div className={classes.index}>{sortIndex + 1}</div>}
              </>
            )}
            {showControls && <ChevronDownIcon className={cx(classes.chevron)} />}
          </div>
        </>
        {!!filter && <Filter tableName={tableName} config={filterConfig} onClose={hideFilter} {...filter} />}
      </div>
      {displayLine && <div className={classes.resizeLine} />}
    </Wrapper>
  );
};

export { Th };
//const MemoizedTh = memo(Th, compareProps) as typeof Th;
//export { MemoizedTh as Th };
