import ListPage from 'components/ListPage';
import * as config from 'schemas/resource';
import { useContext, useMemo } from 'react';
import { ChainImprover } from 'lib/improvers';
import { USER_ROLES } from 'lib/const';
import { AuthContext } from 'providers/AuthProvider';

const chains = [['bahai_postalcodeid', 'bahai_cityid', 'bahai_stateid']];

export const Improver = () => (
  <>
    <ChainImprover chains={chains} forceClearFields={['bahai_postalcodeid']} entityName="resource" />
  </>
);

export const ResourceList = () => {
  const { roles } = useContext(AuthContext);

  const isExportAllowed = useMemo(() => {
    const rolesAllowedForExport = [
      USER_ROLES.RegionalIMSCoordinator,
      USER_ROLES.CommunityAdministrationTeamMember,
      USER_ROLES.NationalInquiryServicesTeamMember,
      USER_ROLES.MembershipTeamMember,
      USER_ROLES.NSASecretariatTeamMember,
      USER_ROLES.AssemblyDevelopmentTeamMember,
      USER_ROLES.ITAdministrator,
      USER_ROLES.InquiryServiceAdministrator,
      USER_ROLES.SupportAdministrator,
      USER_ROLES.SystemAdministrator,
    ];
    return roles.some((item) => rolesAllowedForExport.includes(item as USER_ROLES));
  }, [roles]);

  return (
    <ListPage
      hasViews={false}
      isCreateHidden
      isRemoveHidden
      entityName="resource"
      {...config}
      isExportAllowed={isExportAllowed}
    />
  );
};
