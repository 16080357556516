import classes from './loader.module.scss';
import { ReactNode } from 'react';

export const Loader = ({
  fullScreenOverlay = false,
  text = 'Loading...',
  withoutLoadingText = false,
}: {
  fullScreenOverlay?: boolean;
  text?: ReactNode;
  withoutLoadingText?: boolean;
}) => {
  const loader = (
    <div className={classes.wrapper}>
      <div className={classes.loaderBox}>
        <div className={classes.ldsRing}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={classes.bg} />
      </div>
      {text && !withoutLoadingText && <div>{text}</div>}
    </div>
  );
  return fullScreenOverlay ? <div className={classes.overlay}>{loader}</div> : loader;
};
