import { TCellComponent } from 'components/Table';
import meta from 'config';
import { TEntityName } from 'lib';
import { useMetaData } from 'lib/hooks';
import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState } from 'react';
import { Dialog } from 'components/Dialog';
import tableClasses from 'components/Table/table.module.scss';
import { ReadOnlyValue } from 'components/Form/ReadOnlyValue';
import { Keys } from 'schemas/historylog/index';
import classes from './style.module.scss';
import cx from 'classnames';
import { useTableConfig } from 'lib/helpers';
import { ReactComponent as FilterIcon } from 'components/Table/Components/Th/icons/filter.svg';
import { useFilters } from 'components/Table/hooks';
import { TConfig } from 'components/Table';

const statusFields = ['bahai_changedby', 'bahai_changeddatetime', 'bahai_entitycode', 'bahai_eventname'];

const logFields = ['logicalName', 'newValue', 'oldValue'] as const;
type LogKeys = (typeof logFields)[number];

export const StatusBlock = ({
  fields,
  config,
  data,
  leftSide,
  className,
}: {
  fields: string[];
  config: Record<string, TConfig<Record<string, any>>>;
  data: Record<string, any>;
  leftSide?: boolean;
  className?: string;
}) => (
  <div className={cx(classes.statusFieldsWrapper, { [classes.leftSide]: leftSide }, className)}>
    {fields
      .map((name) => config[name])
      .map(({ name, fieldProps = () => undefined, label, component: Rc }) => (
        <div key={name} className={fieldProps({ classes, values: data, context: 'SINGLE_PAGE' })?.className}>
          <div className={classes.value}>
            {Rc ? (
              <Rc data={data} name={name} defaultValue="---" classes={classes} context={`STATUS_BLOCK`} />
            ) : (
              config[name].adapter(data, name, '---')
            )}
          </div>
          <div className={classes.label}>{label}</div>
        </div>
      ))}
  </div>
);

export const ChangeSet: TCellComponent<Record<Keys | string, any>> = ({ data, defaultValue }) => {
  const entityName = Object.entries(meta).find(([_, v]) => v.name === data.bahai_entitylogicalname)?.[0] as TEntityName;
  const { getLabel, displayName } = useMetaData(entityName);
  const { t } = useTranslation();
  const [displayChanges, setDisplayChanges] = useState(false);
  const { filters } = useFilters();
  const hideChanges = useCallback(() => setDisplayChanges(false), []);

  const showChanges = useCallback(() => setDisplayChanges(true), []);

  const {
    entityConfig: { fields: statusFieldsConfig },
  } = useMetaData('historylog');

  const config = useTableConfig(statusFieldsConfig, {}, 'historylog');

  const { changedAttributes = [] } = useMemo(() => {
    try {
      return JSON.parse(data.bahai_changedata || '{}') as {
        changedAttributes: Record<LogKeys, string>[];
      };
    } catch (e) {
      return { changedAttributes: [] };
    }
  }, [data.bahai_changedata]);

  const mapChanges = useCallback(
    ({ logicalName, ...data }: (typeof changedAttributes)[number], index: number) => (
      <div key={`${logicalName}-${index}`} className={cx(tableClasses.row, classes.row)}>
        <div className={cx(tableClasses.cell, classes.cell)}>{getLabel(logicalName)}</div>
        <div className={cx(tableClasses.cell, classes.cell)}>
          <ReadOnlyValue name={logicalName} entity={entityName} data={{ [logicalName]: data.oldValue }} isAudit />
        </div>
        <div className={cx(tableClasses.cell, classes.cell)}>
          <ReadOnlyValue name={logicalName} entity={entityName} data={{ [logicalName]: data.newValue }} isAudit />
        </div>
      </div>
    ),
    [entityName, getLabel]
  );

  const hasFilters = filters.some((x) => x.attribute === 'bahai_changedata');

  if (changedAttributes.length > 0) {
    return (
      <>
        {data.bahai_eventname !== 'Create' ? (
          <div className={classes.grid}>{changedAttributes.map(mapChanges)}</div>
        ) : (
          <a className={classes.link} onClick={showChanges}>
            {t('{{ count }} field(s) updated', { count: changedAttributes.length })}
          </a>
        )}
        {displayChanges && (
          <Dialog
            onClose={hideChanges}
            showZoom
            className={classes.dialog}
            collapsedLabel={
              (data?.['_objectid_value@OData.Community.Display.V1.FormattedValue'] as any as string) || displayName
            }
          >
            <div className={classes.header}>
              {(data?.['_objectid_value@OData.Community.Display.V1.FormattedValue'] as any as string) || displayName}
            </div>
            <div className={classes.wrapper}>
              <StatusBlock config={config} data={data} fields={statusFields} leftSide />
              <div className={cx(tableClasses.table, classes.table)}>
                <div className={tableClasses.thead}>
                  <div className={cx(tableClasses.th, classes.cell)}>{t('Changed Field')}</div>
                  <div className={cx(tableClasses.th, classes.cell)}>{t('Old Value')}</div>
                  <div className={cx(tableClasses.th, classes.cell)}>
                    {t('New Value')}
                    <div className={tableClasses.iconBox}>
                      {hasFilters && <FilterIcon className={tableClasses.icon} />}
                    </div>
                  </div>
                </div>
                <div className={tableClasses.tbody}>{changedAttributes.map(mapChanges)}</div>
              </div>
            </div>
          </Dialog>
        )}
      </>
    );
  }

  return <>{defaultValue}</>;
};
