import { useCallback, useMemo, useState } from 'react';
import { getFilter, getFilterOptions, PortalFilterOperator, TEntityName, useFilterComponent } from 'lib';
import { useFilters } from 'components/Table/hooks';
import { Dialog } from 'components/Dialog';
import classes from './filter.module.scss';
import cx from 'classnames';
import { TConfig } from 'components/Table/types';
import { useTranslation } from 'react-i18next';
import { Select } from 'components/Select';
import { createPortal } from 'react-dom';

export const Filter = ({
  config: { name, type, textViewField },
  onClose,
  left,
  top,
  tableName,
}: {
  config: TConfig<any>;
  onClose: () => void;
  left: number;
  top: number;
  tableName: string;
}) => {
  const entityName = useMemo(() => tableName.split('.')[0] as TEntityName, [tableName]);
  const options = useMemo(() => new Map(Object.entries(getFilterOptions(type))), [type]);
  const { t } = useTranslation();
  const { filters, addFilter, removeFilter } = useFilters();
  const [filter, setFilter] = useState<PortalFilterOperator | undefined>(() => {
    return (
      filters.find((v) => v.attribute === name)?.operator || (Array.from(options.keys())[0] as PortalFilterOperator)
    );
  });

  const { Component, validate = () => undefined } = useFilterComponent(
    name,
    entityName,
    filter as PortalFilterOperator
  );

  const { hasArguments, formRender } = useMemo(() => getFilter(filter), [filter]);

  const [value, setValue] = useState<string | undefined>(filters.find((v) => v.attribute === name)?.value || '');

  const error = useMemo(() => validate(value), [validate, value]);

  const updateValue = useCallback((value?: string) => {
    setValue(value);
  }, []);

  const onSelectFilter = useCallback(
    ([filter]: string[]) => {
      if (filter) setValue('');
      setFilter(filter as PortalFilterOperator);
    },
    [setFilter]
  );

  const onFilter = useCallback(() => {
    if (filter && (value || !hasArguments)) {
      addFilter({
        operator: filter,
        attribute: name,
        value: typeof value === 'string' ? value.trim() : value,
        textViewField,
      });
      onClose();
    }
  }, [filter, value, hasArguments, addFilter, name, onClose, textViewField]);

  const onClear = useCallback(() => {
    removeFilter(name);
    onClose();
  }, [removeFilter, onClose, name]);

  const FormComponent = useMemo(() => {
    return formRender(Component);
  }, [formRender, Component]);

  return createPortal(
    <Dialog
      className={classes.dialog}
      centered={false}
      styles={{ left: left + 'px', top: top + 'px' }}
      onClose={onClose}
    >
      <h2 className={classes.header}>{t('Filter by')}</h2>
      <Select isClearable={false} items={options} onChange={onSelectFilter} value={filter ? [filter] : []} />
      <div className={classes.inputWrapper} id="filterBySelect">
        {hasArguments && <FormComponent value={value} onChange={updateValue} placeholder={t('Please enter value')} />}
      </div>
      <div className={classes.error}>{error}</div>
      <div className={classes.buttons}>
        <button
          className={cx(classes.btn, classes.primary)}
          disabled={(!value || !!error) && hasArguments}
          onClick={onFilter}
          id="button_filter_apply"
        >
          {t('Apply')}
        </button>
        <button className={cx(classes.btn, classes.secondary)} onClick={onClear}>
          {t('Clear')}
        </button>
      </div>
    </Dialog>,
    document.body
  );
};
